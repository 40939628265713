import { Component, input } from '@angular/core';

@Component({
  selector: 'mars-bottom-upload-option',
  standalone: true,
  templateUrl: './bottom-upload-option.component.html',
  styleUrl: './bottom-upload-option.component.scss',
})
export class BottomUploadOptionComponent {
  disable = input(false);
}

<label
  [for]="name()"
  [formGroup]="formGroup()"
  class="position-relative"
  [class]="inputClass()"
  [ngClass]="{
    active: isDraggedIn,
    error: errorMessage(),
    disabled: disabled() || documentList().length === uploadedFileLimit(),
  }">
  <input
    class="document-input"
    [formControlName]="inputFormControlName()"
    [style.cursor]="
      disabled() || documentList().length === uploadedFileLimit()
        ? 'not-allowed'
        : 'pointer'
    "
    [id]="
      disabled() || documentList().length === uploadedFileLimit() ? '' : id()
    "
    [name]="name()"
    [type]="
      disabled() || documentList().length === uploadedFileLimit()
        ? 'text'
        : 'file'
    "
    [accept]="accept()"
    (click)="checkOnValidators()"
    (change)="onDocumentChange($event)"
    (dragenter)="isDraggedIn = true"
    (drop)="isDraggedIn = false; onDocumentChange($event)"
    [readonly]="
      readonly || disabled() || documentList().length === uploadedFileLimit()
    "
    [value]="value()"
    [dir]="direction()" />

  <div class="document-view at-border at-border-neutral-200 at-p-sm">
    <div class="at-mb-lg">
      <img
        src="./assets/ui-components/placeholder.svg"
        alt="placeholder"
        width="24"
        height="24" />
    </div>

    <div>
      <p class="at-text-neutral-900 at-mb-lg">
        <span class="at-text-primary-500">Upload a file</span> or drag and drop
      </p>
      <div class="at-text-neutral-500">PNG, JPG, GIF upto 5MB</div>
    </div>

    @if (label()) {
      <div>
        <span
          class="at-paragraph-small at-fw-700"
          [ngClass]="{
            'at-text-secondary4':
              disabled() || documentList().length === uploadedFileLimit(),
            'at-text-accent1': !(
              disabled() || documentList().length === uploadedFileLimit()
            ),
          }">
          {{ label() }}
        </span>
      </div>
    }

    @if (documentList()) {
      <div class="hint-message">{{ documentList() }}</div>
    }
  </div>
</label>

@if (errorMessage()) {
  <div
    class="error-message"
    [ngClass]="{
      disabled: disabled(),
    }">
    {{ errorMessage() }}
  </div>
}

@if (documentList() && documentList().length) {
  <div class="document-file-list at-mt-lg">
    @for (file of documentList(); track file; let i = $index) {
      <div class="document-file-item">
        <div class="at-flex at-align-center">
          <div class="document-icon-container at-bg-neutral-50 at-me-md">
            <img
              class="at-me-md"
              src="assets/ui-components/document-text.svg"
              alt="document icon"
              width="16"
              height="16" />
          </div>

          <div class="at-d-flex at-align-items-center">
            <div class="at-w-100 at-text-start">
              <div class="">
                {{ file.name }}
              </div>

              <div class="at-mt-sm at-text-sm at-text-neutral-500">
                {{ file.file.name }}
              </div>
            </div>
          </div>
        </div>

        <div>
          @if (isEditMode() && file.result) {
            <button
              type="button"
              class="at-me-md"
              (click)="downloadFile(file.result)">
              <img
                src="assets/images/svg/download2.svg"
                alt="close icon"
                width="24"
                height="24" />
            </button>
          }
          <button
            class="trash-icon-container at-bg-distructive-50"
            (click)="removeFile(i)">
            <img
              src="assets/ui-components/trash.svg"
              alt="close icon"
              width="24"
              height="24" />
          </button>
        </div>
      </div>
    }
  </div>
}

export const environment = {
  production: false,
  environment: 'dev',
  // baseUrl: 'https://web.dev.contraa.work',
  baseUrl: 'http://localhost:4200',
  api: 'https://admin.dev.contraa.work/',
  version: 'v1',
  auth_key: 'DjUVahdDvHRGDB74pHlSlebipIKsVzoxI4hB5ud6D7qlvHsG832yDX7ZK43GF3yf',
  SENTRY_AUTH_TOKEN:
    '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',
};

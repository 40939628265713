<div class="tabs-container">
  <ul
    class="nav-tabs at-flex at-align-center at-border-bottom at-border-neutral-300"
    [ngClass]="{ pill: tabPill() }">
    @for (tab of tabs; track $index) {
      <li
        class="tab at-flex at-align-center at-py-sm at-px-md"
        [ngClass]="{
          active: tab.active === true && !tabPill(),
          disable: tab.disable(),
          pill: tabPill(),
          pill_active: tab.active === true && tabPill(),
        }"
        (click)="!tab.disable() ? selectTab(tab, $index) : null">
        @if (tab.tabIcon()) {
          <img [src]="tab.tabIcon()" class="tab-icon" />
        }

        <span class="tab-title at-text-sm at-fw-medium">
          {{ tab.tabTitle() }}
        </span>

        @if (tab.tabTag()) {
          <span
            class="tab-tag at-text-xs at-fw-medium at-bg-neutral-50 at-radius-xxl">
            {{ tab.tabTag() }}
          </span>
        }
      </li>
    }
  </ul>

  <ng-content></ng-content>
</div>

export const Path = {
  HOME: 'home',
  ABOUT: 'about',
  FEATURES: 'features',
  BECOME_SUPPLIER: 'become-supplier',
  CONTACT_US: 'contact-us',
  WAITING_FOR_APPROVAL: 'waiting-for-approval',
  SUPPLIER_REGISTRATION: 'supplier-registration',
  BUYER_REGISTRATION: 'buyer-registration',
  WELCOME_TO_CONTRAA: 'welcome-to-contraa',
  LOGIN: 'login',
  SIGN_UP: 'sign-up',
  DASHBOARD: 'dashboard',
};

@defer (when !isVertical()) {
  <div class="horizontal">
    @for (
      option of options();
      track option.label;
      let last = $last;
      let i = $index
    ) {
      <div class="options">
        <div class="icon">
          <img
            [src]="option.icon || 'assets/icons/' + option.status + '.svg'"
            [alt]="option.status" />
          @if (!last) {
            <div
              [class.at-bg-supplier-500]="
                option.status !== 'inactive' &&
                options()[i + 1].status !== 'inactive'
              "
              class="line at-bg-neutral-200"></div>
          }
        </div>
        <div class="at-mt-x-md">
          <p class="at-text-md at-text-neutral-900">{{ option.label }}</p>
        </div>
      </div>
    }
  </div>
}

@defer (when isVertical()) {
  <div class="vertical at-row at-flex-column at-align-start at-ms-6xl">
    @for (option of options(); track option; let first = $first) {
      <div
        class="at-row at-align-end"
        [class.active-step]="option.status === 'active'">
        <div class="at-row at-flex-column at-align-center">
          @if (!first) {
            <div
              [class.at-bg-supplier-500]="option.status !== 'inactive'"
              class="v-line at-bg-neutral-200"></div>
          }
          <img
            [src]="option.icon || 'assets/icons/' + option.status + '.svg'"
            [alt]="option.status" />
        </div>
        <div [class.info]="option.description" class="at-ms-4xl">
          <p
            [class.at-mb]="!option.description"
            class="at-text-md at-text-neutral-900">
            {{ option.label }}
          </p>
          <p class="at-text-sm at-text-neutral-500">{{ option.description }}</p>
        </div>
      </div>
    }
  </div>
}

import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mars-alert-sticky',
  imports: [CommonModule],
  templateUrl: './alert-sticky.component.html',
  styleUrl: './alert-sticky.component.scss',
})
export class AlertStickyComponent {
  icon = input<string>();
  message = input<string>();
  inlineClasses = input<string>();
  style = input<Styles>('primary1');

  styles = {
    primary1: 'at-bg-primary-500 at-text-white',
    primary2: 'at-bg-primary-50 at-text-primary-700',
    neutral: 'at-bg-neutra-50 at-text-neutra-700',
    white: 'at-bg-white at-text-neutra-700',
  };

  protected isShownAlert = true;

  protected closeAlert() {
    this.isShownAlert = false;
  }
}

type Styles = 'primary1' | 'primary2' | 'neutral' | 'white';

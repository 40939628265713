import { Component, inject } from '@angular/core';
import { LandingCardImgComponent } from '../../../../../common/components/landing-card-img/landing-card-img.component';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'mars-how-it-works',
  standalone: true,
  imports: [LandingCardImgComponent, TranslocoModule],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss',
})
export class HowItWorksComponent {
  translocoService = inject(TranslocoService);

  howItWorks = [
    {
      title: this.translocoService.translate(
        'landing_page_how_it_works_section_step_1_title'
      ),
      description: this.translocoService.translate(
        'landing_page_how_it_works_section_step_1_description'
      ),
      image: '/assets/images/landing/card-1-image.png',
      position: 'right' as 'right' | 'left',
    },

    {
      title: this.translocoService.translate(
        'landing_page_how_it_works_section_step_2_title'
      ),
      description: this.translocoService.translate(
        'landing_page_how_it_works_section_step_2_description'
      ),
      image: '/assets/images/landing/card-2-image.png',
      position: 'left' as 'right' | 'left',
    },

    {
      title: this.translocoService.translate(
        'landing_page_how_it_works_section_step_3_title'
      ),
      description: this.translocoService.translate(
        'landing_page_how_it_works_section_step_3_description'
      ),
      image: '/assets/images/landing/card-3-image.png',
      position: 'right' as 'right' | 'left',
    },
  ];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, input, model, OnInit, output } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'mars-single-checkbox',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './single-checkbox.component.html',
  styleUrls: ['./single-checkbox.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class SingleCheckboxComponent implements OnInit {
  inputFormControlName = input.required<string>();
  id = input.required<string | number>();

  label = input<string>();
  name = input.required<string>();
  value = input.required();
  description = input();
  checked = input<boolean>(false);

  disabled = input<boolean>(false);
  required = model<boolean>(false);

  errorMessage = input<string>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onSelectItem = output<{ checked: boolean; value: any }>();

  private readonly parentContainer = inject(ControlContainer);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }

    if (
      this.required() &&
      !this.childControl.hasValidator(Validators.required)
    ) {
      this.childControl.addValidators(Validators.required);
    }

    if (
      !this.required() &&
      this.childControl.hasValidator(Validators.required)
    ) {
      this.required.set(true);
    }
  }

  toggleElement(event: any, row: any) {
    this.onSelectItem.emit({ checked: event.target.checked, value: row });
  }
}

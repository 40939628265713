import { Component, input } from '@angular/core';
import { StepperOption } from './stepper-options.interface';

@Component({
  selector: 'mars-stepper',
  imports: [],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  options = input.required<StepperOption[]>();
  isVertical = input<boolean>(false);
}

import {
  Component,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  model,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FileCardComponent } from './file-card/file-card.component';
import { BottomUploadOptionComponent } from './bottom-upload-option/bottom-upload-option.component';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { ClickOutSideDirective } from '../../directives/clickOutSide.directive';
import {
  Attachment,
  UploadedFile,
} from '../../interfaces/attachment.interface';
import { UploadFilesService } from '../../services/upload-files.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mars-upload-files',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FileCardComponent,
    BottomUploadOptionComponent,
    FileSizePipe,
    ClickOutSideDirective,
  ],
  templateUrl: './upload-files.component.html',
  styleUrl: './upload-files.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFilesComponent),
      multi: true,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  private readonly uploadFilesService = inject(UploadFilesService);
  private readonly parentContainer = inject(ControlContainer);

  /* Inputs */
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  name = input.required<string>();
  flow = input.required<string>();

  label = input<string>();
  disabled = input<boolean>(false);
  multiple = input<boolean>(false);
  fileSizeLimit = input<number>(1000000); // 1 mega
  accept = input<string>();

  /* Computed */
  acceptedFormats = computed(() => {
    return this.uploadFilesService.createAcceptedFormateHint(this.accept());
  });

  /* Models */
  uploadedFileLimit = model<number>(1);
  required = model<boolean>(false);

  /* Outputs */
  getFiles = output<UploadedFile[]>();
  filesChanged = output<UploadedFile[]>();
  continue = output<UploadedFile[]>();

  protected isFileUploadBoxOpen = signal(false);
  protected isFileListOpen = signal(false);

  protected files = signal<UploadedFile[]>([]);

  subscriptions: Subscription[] = [];

  /* FormGroup and Control */

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  constructor() {
    effect(() => {
      const isFileUploadBoxOpenEffect = this.isFileUploadBoxOpen();

      if (isFileUploadBoxOpenEffect) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    });

    effect(() => {
      this.getFiles.emit(this.files());
    });
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }

    if (
      (this.required() &&
        !this.childControl?.hasValidator(Validators.required)) ||
      (!this.required() && this.childControl?.hasValidator(Validators.required))
    ) {
      this.childControl.addValidators(Validators.required);
    }

    if (!this.multiple() && this.uploadedFileLimit() > 1)
      this.uploadedFileLimit.set(1);

    this.setFilesDataBasedOnFormControlValue();
  }

  change(fileInput: Event) {
    if (this.disabled()) return;
    const targetFiles = (<HTMLInputElement>fileInput.target).files;

    if (targetFiles) {
      for (
        let i = 0;
        i < targetFiles.length &&
        i != this.uploadedFileLimit() &&
        this.files()?.length < this.uploadedFileLimit();
        i++
      ) {
        const file = targetFiles.item(i);
        if (file && file.size <= this.fileSizeLimit()) {
          this.uploadFile(file);
        }
      }

      this.filesChanged.emit(this.files());
    }
  }

  reuploadFile(index: number) {
    const files = [...this.files()];
    this.files().splice(index, 1);

    if (files[index]?.file) this.uploadFile(files[index].file);
  }

  uploadFile(file: File) {
    this.files.set([...this.files(), { id: null, file, uploading: 0 }]);
    const uploadingFile = this.files()[this.files()?.length - 1];

    const uploadInterval = window.setInterval(() => {
      if ((uploadingFile.uploading as number) < 90) {
        (uploadingFile.uploading as number)++;
      } else {
        clearInterval(uploadInterval);
      }
    }, 100);

    uploadingFile.intervalId = uploadInterval;

    const subscribe = this.uploadFilesService
      .addFile(this.flow(), this.inputFormControlName(), file)
      .subscribe({
        next: response => {
          if (response?.media) {
            const control = this.childControl.value ?? [];
            this.childControl.setValue([...control, response?.media?.id]);

            uploadingFile.uploading = 100;
            uploadingFile.id = response?.media?.id;
          }
        },
        error: err => {
          uploadingFile.uploading = 100;
          uploadingFile.error = err.message;
          this.isFilesHasErrors();
          clearInterval(uploadInterval);
        },
        complete: () => {
          this.isFilesHasErrors()
          clearInterval(uploadInterval);
        },
      });

    this.subscriptions.push(subscribe);
  }

  cancelUploading(fileIndex: number) {
    this.childControl.setErrors({...this.childControl.errors, invalidFile: true});
    window.clearInterval(this.files()[fileIndex].intervalId);
    this.files()[fileIndex].uploading = 0;
    this.subscriptions[fileIndex].unsubscribe();
  }

  removeFile(
    index: number,
    fileId: number | null,
    fileInput?: HTMLInputElement
  ) {
    const files = [...this.files()];
    if (fileInput) fileInput.value = '';
    if (!fileId) {
      files.splice(index, 1);
      this.files.set(files);
      this.subscriptions.splice(index, 1);
      this.isFilesHasErrors()
    } else {
      this.files()[index].deleting = true;
      this.uploadFilesService.deleteFile(fileId).subscribe({
        next: () => {
          this.files()[index].deleting = false;
          files.splice(index, 1);
          this.files.set(files);
          this.subscriptions.splice(index, 1);
          this.childControl.setValue(
            (this.childControl.value as number[]).filter(id => fileId != id)
          );
          this.isFilesHasErrors()
          this.updateControlValueBasedOnFiles();
        },
      });
    }
  }

  isFilesHasErrors() {
    const filesHasErrors = this.files().find(file => !file.id);
    if(filesHasErrors) {
      this.childControl.setErrors({...this.childControl.errors, invalidFile: true});
      
    }else {
      const errors = {...this.childControl.errors};
      delete errors?.['invalidFile'];

      this.childControl.setErrors(Object.entries(errors).length == 0  ? null : errors);
        }
        console.log(this.childControl.errors);
      }

  downloadFile(fileId: number | null, index: number) {
    if (fileId) {
      this.files()[index].downloading = true;
      this.uploadFilesService.downloadFile(fileId).subscribe({
        next: response => {
          this.files()[index].downloading = false;
          this.uploadFilesService.downloadFromUrl(
            (response as { url: string }).url
          );
        },
      });
    }
  }

  continueProcess() {
    if (!this.childControl.value || this.childControl.value.length) return;
    this.continue.emit(this.files());
  }

  setFilesDataBasedOnFormControlValue() {
    if (this.childControl?.value) {
      const value = [...this.childControl.value] as Attachment[];
      this.childControl.setValue([]);

      value.forEach(doc => {
        const controlValue = [...this.childControl.value, doc.id];
        this.childControl.setValue(controlValue);
        this.subscriptions.push(new Subscription());
        this.files.set([
          ...this.files(),
          { id: doc.id, file: null, uploading: null, uploadedFile: doc },
        ]);
      });
    }
  }

  updateControlValueBasedOnFiles() {
    if (!this.childControl.value || !this.childControl.value?.length) {
      this.childControl.setValue([]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscribe => {
      subscribe.unsubscribe();
    });
  }
}

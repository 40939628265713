import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToggleOption } from '../../interfaces/option.interface';

@Component({
  selector: 'mars-radio-button',
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  id = input.required<string>();
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  label = input<string>();
  disabled = input<boolean>();
  options = input<ToggleOption[]>([]);
}

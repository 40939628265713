<label class="container" [class]="{ disabled: disabled() }">
  <input
    [formControlName]="inputFormControlName()"
    type="checkbox"
    class="checkbox"
    [attr.name]="name()"
    [value]="value()"
    [id]="id()"
    [checked]="checked()"
    (change)="toggleElement($event, value())" />

  <span class="checkmark"></span>

  <div class="container__content">
    @if (label()) {
      <div class="option_label at-text-neutral-900 at-text-sm" [id]="id()">
        {{ label() }}
      </div>
    }
    @if (description()) {
      <div class="option_description">{{ description() }}</div>
    }
  </div>
</label>

@if (errorMessage()) {
  <div
    class="at-paragraph-extra-small at-text-failure at-mt-1 at-text-start"
    [class.at-paragraph-secondary3]="disabled()">
    {{ errorMessage() }}
  </div>
}

import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LanguageService } from '../services/language/language.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  req = req.clone({
    headers: req.headers
      .set('language', inject(LanguageService).currentLang())
      .set('X-Authorization', environment.auth_key)
      .set(
        'Authorization',
        `Bearer ${inject(AuthService).userData()?.auth?.token}`
      ),
  });

  return next(req);
};

@for (option of options(); track option; let idx = $index) {
  <label
    class="container at-flex at-align-start at-position-relative at-cursor-pointer at-text-md at-mb-sm"
    [ngClass]="{ disabled: option.disabled }"
    [formGroup]="formGroup()">
    <input
      [id]="id() + '_label_' + idx"
      type="checkbox"
      class="checkbox at-position-absolute at-cursor-pointer"
      [attr.name]="inputFormControlName()"
      [value]="option.value"
      [formControlName]="inputFormControlName()" />

    <span
      class="checkmark at-rounded-circle at-mt-x-sm at-bg-neutral-300 at-me-sm at-position-relative"></span>

    <div
      class="container__content at-cursor-pointer at-text-md at-fw-medium at-flex at-flex-column">
      @if (option && option.label) {
        <div class="option_label at-text-neutral-900">{{ option.label }}</div>
      }

      @if (option && option.description) {
        <div class="option_description at-text-neutral-500">
          {{ option.description }}
        </div>
      }
    </div>
  </label>
}

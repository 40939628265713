import {
  Directive,
  ElementRef,
  HostListener,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef,
  input,
  inject,
  ChangeDetectorRef,
  output,
} from '@angular/core';
import { TooltipComponent, TooltipPlacement } from './tooltip.component';

@Directive({
  selector: '[marsTooltip]',
})
export class TooltipDirective {
  marsTooltip = input<string>('');
  description = input<string>();

  hasCloseIcon = input<boolean>(true);
  hasActionButton = input<boolean>(false);
  actionButtonLabel = input<string>();
  buttonAction = output();

  placement = input<TooltipPlacement>('right');

  private readonly el = inject(ElementRef);
  private readonly vcr = inject(ViewContainerRef);
  private readonly resolver = inject(ComponentFactoryResolver);
  private readonly cdr = inject(ChangeDetectorRef);

  private tooltipRef: ComponentRef<TooltipComponent> | undefined;

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltipRef) {
      const factory = this.resolver.resolveComponentFactory(TooltipComponent);
      this.tooltipRef = this.vcr.createComponent(factory);

      this.setProperties();
      this.setPosition();

      this.cdr.detectChanges();
    }
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
    const tooltipElement = this.tooltipRef?.location.nativeElement;
    if (
      tooltipElement &&
      tooltipElement.contains(event.relatedTarget as Node)
    ) {
      return;
    }
    this.destroyTooltip();
  }

  private setProperties() {
    if (this.tooltipRef) {
      this.tooltipRef.instance.title = this.marsTooltip;
      this.tooltipRef.instance.description = this.description;
      this.tooltipRef.instance.hasCloseIcon = this.hasCloseIcon;
      this.tooltipRef.instance.hasActionButton = this.hasActionButton;
      this.tooltipRef.instance.actionButtonLabel = this.actionButtonLabel;
      this.tooltipRef.instance.placement = this.placement;

      this.tooltipRef.instance.action.subscribe(() =>
        this.onTooltipButtonClick()
      );
      this.tooltipRef.instance.mouseLeftToolTip.subscribe(() =>
        this.onCloseToolTip()
      );
      this.tooltipRef.instance.close.subscribe(() => this.onCloseToolTip());
    }
  }

  private setPosition() {
    this.cdr.detectChanges();

    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipWidth = this.tooltipRef?.instance.templateWidth;
    const tooltipHeight = this.tooltipRef?.instance.templateHeight;

    let top, left;

    if (tooltipWidth && tooltipHeight) {
      switch (this.placement()) {
        case 'top':
          top = hostPos.top - tooltipHeight - 20;
          left = hostPos.left + (hostPos.width - tooltipWidth) / 2;
          break;
        case 'bottom':
          top = hostPos.bottom + 20;
          left = hostPos.left + (hostPos.width - tooltipWidth) / 2;
          break;
        case 'left':
          top = hostPos.top - tooltipHeight / 2;
          left = hostPos.left - tooltipWidth - 10;
          break;
        case 'right':
          top = hostPos.top - tooltipHeight / 2;
          left = hostPos.right + 10;
          break;
      }
    }

    if (this.tooltipRef) {
      this.tooltipRef.instance.top = top;
      this.tooltipRef.instance.left = left;
    }
  }

  private destroyTooltip() {
    if (this.tooltipRef) {
      this.tooltipRef.destroy();
      this.tooltipRef = undefined;
    }
  }

  private onCloseToolTip() {
    this.destroyTooltip();
  }

  private onTooltipButtonClick() {
    this.buttonAction.emit();
    this.destroyTooltip();
  }
}

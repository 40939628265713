import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IDeactivateGuard } from '../Interfaces/DeactivateGuard.interface';

export const DeactivateGuard: CanDeactivateFn<IDeactivateGuard> = (
  component: IDeactivateGuard
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  return component.canExit ? component.canExit() : true;
};

import { inject, Pipe, PipeTransform, signal } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';

@Pipe({
  name: 'navigation',
  standalone: true,
})
export class NavigationPipe implements PipeTransform {
  languageService = inject(LanguageService);
  currentLang = signal(this.languageService.currentLang());

  transform(value: string): string {
    return this.currentLang() + '/' + value;
  }
}

import { environment } from '../../../environments/environment';

export const ENDPOINTS = {
  POST_USER_REGISTRATION:
    environment.api + 'api/' + environment.version + '/auth/register',
  POST_USER_LOGIN:
    environment.api + 'api/' + environment.version + '/auth/login',
  POST_LOGOUT: environment.api + 'api/' + environment.version + '/auth/logout',
  GET_COUNTRIES: environment.api + 'api/' + environment.version + '/countries',
  POST_BUYER_REGISTRATION:
    environment.api + 'api/' + environment.version + '/buyer',
  POST_SKIP_BUYER_REGISTRATION:
    environment.api + 'api/' + environment.version + '/buyer/skip-registration',
};

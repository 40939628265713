import {
  Component,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  AvatarComponent,
  BadgeComponent,
  ClickOutSideDirective,
} from '@mars/ui-components';
import { AuthService } from '../../services/auth/auth.service';
import { defineStateBasedOnUserState } from '../../guards/userState.guard';
import { RouteStrategyFactory } from '../../guards/user-strategies/route-strategy.factory';

@Component({
  selector: 'mars-user-info',
  standalone: true,
  imports: [AvatarComponent, ClickOutSideDirective, BadgeComponent],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss',
})
export class UserInfoComponent implements OnInit {
  logout = output();
  isLoggingOut = input(false);

  protected readonly authService = inject(AuthService);
  protected showDropdown = signal(false);

  protected userType: string | null = null;

  ngOnInit(): void {
    this.authService.userState$.subscribe(value => {
      const state = defineStateBasedOnUserState(value)?.user;
      this.userType = state
        ? RouteStrategyFactory.getStrategy(state).title
        : null;
    });
  }

  userLogout() {
    if (!this.isLoggingOut()) this.logout.emit();
  }
}

<!-- [style.opacity]="isOpened() ? 1 : 0" -->
<div
  (mouseleave)="onMouseLeave()"
  [style.top]="top + 'px'"
  [style.inset-inline-start]="left + 'px'"
  class="tooltip at-bg-white at-p-md at-radius-md at-shadow-lg"
  #tooltip>
  <div [class]="'tooltip-arrow tooltip-arrow-' + placement()"></div>
  <div class="at-flex at-justify-between at-align-start">
    <p class="at-text-neutral-900 at-fw-medium at-text-xs">{{ title() }}</p>

    @if (hasCloseIcon()) {
      <button (click)="closeTooltip()">
        <img src="assets/icons/close/neutral.svg" alt="close-icon" />
      </button>
    }
  </div>
  @if (description()) {
    <p class="description at-text-neutral-500 at-text-xs at-mb-md at-mt-x-sm">
      {{ description() }}
    </p>
  }
  @if (hasActionButton()) {
    <div class="at-flex at-justify-end">
      <button
        (click)="makeActionWhenClickActionButton()"
        class="action at-bg-white at-text-neutral-700 at-text-sm at-fw-medium at-border at-border-neutral-300 at-radius-sm">
        {{ actionButtonLabel() }}
      </button>
    </div>
  }
</div>

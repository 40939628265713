import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TopNavbarComponent } from './common/components/top-navbar/top-navbar.component';
import { AlertInlineComponent } from '@mars/ui-components';
import { ToasterService } from '@mars/ui-components';

@Component({
  imports: [RouterOutlet, TopNavbarComponent, AlertInlineComponent],
  selector: 'mars-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'mars';

  protected readonly toastService = inject(ToasterService);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ClickOutSideDirective } from '../../directives/clickOutSide.directive';

@Component({
  selector: 'mars-select',
  imports: [CommonModule, ReactiveFormsModule, ClickOutSideDirective],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class SelectComponent implements OnInit, AfterViewInit, OnDestroy {
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  name = input.required<string>();

  label = input<string>();
  placeholder = input<string>('');
  inputClass = input<string>('');
  inputStyle = input();

  disabled = input<boolean>(false);
  readonly = input<boolean>();
  value = input<string | number>();
  required = input<boolean>(false);

  options = input<any[]>([]);
  optionValueKey = input<string>();
  optionLabelKey = input<string>();
  selectedOptionLabelKey = input<string>();

  showArrow = input(true);
  isLoadingInput = input(false);

  hasSearch = input<boolean>(); // to add search option
  searchPlaceholder = input<string>('Search ...'); //to be able to translate or edit it
  noSearchResultLabel = input<string>('No search result'); //to be able to translate or edit it

  changeOption = output<any>();
  isDropdownOpen = false;
  selectedOption: any;
  selectedValue?: string | number;
  focused?: boolean;
  notEmpty?: boolean;
  searchValue!: string;
  showNoSearchResult!: boolean;
  filteredOptions: any[] = [];

  private readonly destroy$ = new Subject<void>();
  private readonly parentContainer = inject(ControlContainer);
  private readonly cdr = inject(ChangeDetectorRef);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }
    if (this.optionLabelKey()) this.selectedOption = <any>{};
  }

  toggleDropdown() {
    this.filteredOptions = [];
    this.childControl.markAsTouched();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngAfterViewInit(): void {
    if (this.childControl) {
      this.writeValue(this.childControl.value, false);
      this.cdr.detectChanges();

      this.childControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          this.writeValue(value, false);
        });
    }
  }

  writeValue(value: string | number, updateFormControlValue?: boolean) {
    if (value && this.options()) {
      const match = this.options().find((item: any) => {
        return (
          (this.optionValueKey() ? item[this.optionValueKey() ?? ''] : item) ===
          value
        );
      });
      if (match) {
        this.selectOption(match, updateFormControlValue);
      }
    }
  }

  selectOption(item: any, updateFormControlValue?: boolean) {
    if (item) {
      this.selectedOption = item;
      if (updateFormControlValue) {
        this.childControl.patchValue(
          this.optionValueKey() ? item[this.optionValueKey() ?? ''] : item
        );
      }
      this.filteredOptions = [];
      this.selectedValue = this.optionLabelKey()
        ? item[this.optionLabelKey() ?? '']
        : item;
      this.changeOption.emit(item);
      this.notEmpty = true;
    }
  }

  onDropdownSearch(event: Event) {
    const searchValue = (<HTMLInputElement>event.target).value;
    if (searchValue) {
      if (this.options()) {
        this.filteredOptions = this.options().filter((item: any) => {
          return item[this.optionLabelKey() ?? 'name']
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase());
        });

        this.showNoSearchResult = !this.filteredOptions.length;
      } else {
        this.showNoSearchResult = true;
      }
    } else {
      this.filteredOptions = [];
    }
  }

  clickedOutside() {
    this.isDropdownOpen = false;
    this.childControl.markAsDirty();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

<div class="file at-bg-neutral-50 at-flex at-justify-between at-align-center at-py-lg at-gap-lg">
  <div class="at-flex at-gap-md at-align-center at-flex-grow at-word-break-all">
    <div class="icon file-icon at-bg-supplier-50 at-flex at-justify-center at-align-center">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.6666 6.66659V9.99992C14.6666 13.3333 13.3333 14.6666 9.99998 14.6666H5.99998C2.66665 14.6666 1.33331 13.3333 1.33331 9.99992V5.99992C1.33331 2.66659 2.66665 1.33325 5.99998 1.33325H9.33331"
          stroke="#4720CD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.6666 6.66659H12C9.99998 6.66659 9.33331 5.99992 9.33331 3.99992V1.33325L14.6666 6.66659Z"
          stroke="#4720CD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4.66669 8.66675H8.66669" stroke="#4720CD" stroke-width="1.2" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M4.66669 11.3333H7.33335" stroke="#4720CD" stroke-width="1.2" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>

    <div class="file-info at-flex at-flex-column at-gap-sm at-flex-grow">
      <p class="file-name at-text-neutral-900 at-text-md">
        {{ file() ? file()?.name : uploadedFile()?.name }}
      </p>
      @if (showProgress() && progress_percentage() > 0) {
      <div class="progress-container at-bg-neutral-200">
        <div [style]="{ width: progress_percentage() + '%' }" class="progress-bar at-bg-supplier-500 at-h-100"></div>
      </div>
      }

      <div class="hints at-flex at-justify-between at-align-center">
        @if (errorMessage()) {
        <p class="error at-text-destructive-500 at-text-sm">
          {{ errorMessage() }}
        </p>
        } @else {
        @if (showSize()) {
        <p class="file-size at-text-neutral-400 at-text-sm">
          {{
          (file() ? file()?.size : uploadedFile()?.size) ?? 0 | fileSize
          }}
        </p>
        }

        @if (progress_percentage() > 0 && progress_percentage() < 100) { <p
          class="progress at-text-neutral-900 at-text-sm at-fw-medium">
          {{ progress_percentage() + '%' }}
          </p>
          }
          }
      </div>
    </div>
  </div>

  @if (progress_percentage() > 0 && progress_percentage() < 100) { <button type="button" (click)="cancelUploadFile()"
    title="cancel" class="icon">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.16747 4.16663L15.8333 15.8325" stroke="#111827" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M4.16671 15.8325L15.8326 4.16663" stroke="#111827" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    </button>
    } @else {
    <div class="action-btns at-flex at-gap-lg">
      <button [disabled]="isDeleting()" type="button" (click)="deleteFile()" title="delete"
        class="icon delete-icon at-bg-destructive-50 at-flex at-justify-center at-align-center">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
            stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M7.08333 4.14175L7.26666 3.05008C7.4 2.25841 7.49999 1.66675 8.90833 1.66675H11.0917C12.5 1.66675 12.6083 2.29175 12.7333 3.05841L12.9167 4.14175"
            stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M15.7083 7.6167L15.1667 16.0084C15.075 17.3167 15 18.3334 12.675 18.3334H7.325C5 18.3334 4.92501 17.3167 4.83334 16.0084L4.29167 7.6167"
            stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.60834 13.75H11.3833" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M7.91667 10.4167H12.0833" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </button>
      @if (errorMessage() || cancelled()) {
      <button type="button" (click)="retryUploadFile()" class="icon" title="Retry">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M18.3333 10.0001C18.3333 14.6001 14.6 18.3334 10 18.3334C5.40001 18.3334 2.59167 13.7001 2.59167 13.7001M2.59167 13.7001H6.35834M2.59167 13.7001V17.8667M1.66667 10.0001C1.66667 5.40008 5.36667 1.66675 10 1.66675C15.5583 1.66675 18.3333 6.30008 18.3333 6.30008M18.3333 6.30008V2.13341M18.3333 6.30008H14.6333"
            stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      }


      @if (downloadable() && !errorMessage()) {
      <button [disabled]="isDownloading()" type="button" (click)="downloadFile()" title="download"
        class="icon at-flex at-justify-center at-align-center">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 9.16675V14.1667L9.16667 12.5001" stroke="#111827" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M7.50016 14.1667L5.8335 12.5" stroke="#111827" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M18.3332 8.33342V12.5001C18.3332 16.6667 16.6665 18.3334 12.4998 18.3334H7.49984C3.33317 18.3334 1.6665 16.6667 1.6665 12.5001V7.50008C1.6665 3.33341 3.33317 1.66675 7.49984 1.66675H11.6665"
            stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.3332 8.33341H14.9998C12.4998 8.33341 11.6665 7.50008 11.6665 5.00008V1.66675L18.3332 8.33341Z"
            stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      }
    </div>
    }

</div>
import { Component, input, output, signal } from '@angular/core';
import { FileSizePipe } from '../../../pipes/file-size.pipe';
import { Attachment } from '../../../interfaces/attachment.interface';

@Component({
  selector: 'mars-file-card',
  standalone: true,
  imports: [FileSizePipe],
  templateUrl: './file-card.component.html',
  styleUrl: './file-card.component.scss',
})
export class FileCardComponent {
  file = input.required<File | null>();
  uploadedFile = input<Attachment>();
  downloadable = input(false);
  progress_percentage = input(0);
  errorMessage = input('');
  showProgress = input(true);
  showSize = input(true);
  delete = output();
  download = output();
  cancelUploading = output();
  retry = output();
  isDeleting = input(false);
  isDownloading = input(false);

  cancelled = signal(false);

  deleteFile() {
    this.delete.emit();
  }

  downloadFile() {
    this.download.emit();
  }

  retryUploadFile() {
    this.cancelled.set(false);
    this.retry.emit();
  }

  cancelUploadFile() {
    this.cancelled.set(true);
    this.cancelUploading.emit();
  }
}

import { Injectable, signal } from '@angular/core';
import { status } from '../../components/alert-inline/alertData.interface';
import { Toast } from '../../interfaces/toast.interface';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  showToast = signal(false);
  toasterData = signal<Toast>({
    alertData: {},
    hasCloseIcon: true,
    hasIcon: false,
  });
  toasterTimeout = 3000;
  interval: any;

  add(
    title: string,
    description: string,
    icon: string,
    status: status,
    hasIcon = false,
    hasCloseIcon = true
  ) {
    this.showToast.set(true);
    this.toasterData.set({
      alertData: {
        title,
        description,
        icon,
        status,
      },
      hasIcon,
      hasCloseIcon,
    });
    this.closeToaster();
  }

  closeToaster() {
    this.interval = setTimeout(() => {
      this.showToast.set(false);
    }, this.toasterTimeout);
  }

  resetInterval() {
    clearInterval(this.interval);
  }
}

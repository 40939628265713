import { Path } from '../../constants/paths.constant';
import { UserState } from '../../constants/userStates.constant';
import { UserStateTypes } from '../../models/user';
import { RouteStrategy } from './route-stratgy.interface';

export class BuyerRouteStrategy implements RouteStrategy {
  title = 'Buyer';

  private availableRoutes = {
    '': [Path.WELCOME_TO_CONTRAA, Path.BUYER_REGISTRATION],
    [UserState.DRAFT]: [Path.DASHBOARD],
    [UserState.PENDING_APPROVAL]: [Path.DASHBOARD],
    [UserState.APPROVE]: [Path.DASHBOARD],
    [UserState.REJECTED]: [Path.BUYER_REGISTRATION],
  };

  isAllowed(
    url: string,
    userState: Exclude<UserStateTypes, null> | ''
  ): boolean {
    return this.availableRoutes[userState]?.includes(url) || false;
  }

  getRedirectPath(userState: string): string {
    if (userState === UserState.REJECTED) {
      return Path.BUYER_REGISTRATION;
    } else if (userState === '') {
      return Path.WELCOME_TO_CONTRAA;
    } else {
      return Path.DASHBOARD;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, separator = ''): unknown {
    if (value >= 1000000) {
      return this.formatNumber(value / 1000000) + separator + 'MB';
    } else if (value < 1000000) {
      return this.formatNumber(value / 1000) + separator + 'KB';
    }
    return null;
  }

  formatNumber(num: number): number {
    return num % 1 === 0 ? num : parseFloat(num.toFixed(1));
  }
}

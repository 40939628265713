/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from '@angular/core';
import { Attachment } from '../interfaces/attachment.interface';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  private readonly http = inject(HttpClient);

  addFile(
    flow: string,
    input: string,
    file: File
  ): Observable<{ media: any; errors?: unknown }> {
    const url = environment.api + 'api/v1/media';
    const formData = new FormData();
    formData.append('flow', flow);
    formData.append('input', input);
    formData.append('file', file);

    return this.http.post(url, formData) as Observable<{
      media: any;
      errors?: unknown;
    }>;
  }

  downloadFile(id: number) {
    const url = `${environment.api}api/v1/media/${id}/download`;
    return this.http.get(url);
  }

  downloadFromUrl(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = url;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  deleteFile(id: number) {
    const url = `${environment.api}api/v1/media/${id}`;
    return this.http.delete(url, {
      body: {
        _method: 'post',
      },
    });
  }

  createAcceptedFormateHint(acceptedFormatsArray: string | undefined): string {
    const formatsArray = acceptedFormatsArray?.split(',');
    if (!formatsArray || formatsArray.length === 0) {
      return 'All formats are accepted';
    }

    if (formatsArray.length === 1) {
      return `Accepted Format is ${formatsArray[0]}`;
    }

    const formattedFormats = formatsArray
      .map((format, i) => {
        if (i === formatsArray.length - 1) {
          return ` and ${format}`;
        } else if (i === 0) {
          return format;
        } else {
          return `, ${format}`;
        }
      })
      .join('');

    return `Formats accepted are ${formattedFormats}`;
  }

  async convertUrlToFile(doc: Attachment): Promise<File | null> {
    // Fetch the file as a Blob
    const blob = await firstValueFrom(
      this.http.get(doc.url, { responseType: 'blob' })
    );

    if (blob) {
      // Convert the Blob to a File
      const file = new File([blob], doc.name, { type: blob.type });
      return file;
    }

    return null;
  }
}

<section
  class="at-px-lg md:at-px-6xl at-bg-white at-mb-lg md:at-mb-6xl xl:at-mb-7xl">
  <div class="at-mb-6xl">
    <h3 class="at-text-lg at-fw-extra-bold at-text-primary-500 at-mb-md">
      {{ 'landing_page_why_join_contraa_section_title' | transloco }}
    </h3>
    <p class="answer at-text-sm at-text-neutral-700 text-width">
      {{ 'landing_page_why_join_contraa_section_answer' | transloco }}
    </p>
  </div>

  <div class="container at-gap-3xl">
    <div class="cards at-gap-3xl">
      @for (reason of reasons; track reason.title) {
        <mars-landing-card
          cardClass="rtl:at-dir-rtl"
          [svg]="reason.svg"
          [title]="reason.title"
          [description]="reason.description" />
      }
    </div>

    <div
      class="img-container at-flex at-justify-center at-bg-supplier-500 at-radius-xxxl">
      @defer (on viewport()) {
        <mars-why-join-contraa-animation />
      } @placeholder {
        <div>loading...</div>
      }
    </div>
  </div>
</section>

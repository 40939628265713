import { BuyerRouteStrategy } from './buyer.strategy';
import { RouteStrategy } from './route-stratgy.interface';
import { SupplierRouteStrategy } from './supplier.strategy';
import { UserRouteStrategy } from './user.strategy';

export class RouteStrategyFactory {
  static getStrategy(userType: 'buyer' | 'supplier' | null): RouteStrategy {
    switch (userType) {
      case 'buyer':
        return new BuyerRouteStrategy();
      case 'supplier':
        return new SupplierRouteStrategy();
      case null:
        return new UserRouteStrategy();
      default:
        throw new Error('Invalid user type');
    }
  }
}

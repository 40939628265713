import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { effect, inject } from '@angular/core';
import { LanguageService } from '../services/language/language.service';
import { Path } from '../constants/paths.constant';
import { NavigationPipe } from '../pipes/navigation/navigation.pipe';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const navigation = new NavigationPipe();
  const router = inject(Router);

  effect(() => {
    const isLoggedIn = authService.isLoggedIn();

    if (!isLoggedIn) {
      router.navigate(['/' + navigation.transform(Path.LOGIN)]);
    }
  });

  if (!authService.isLoggedIn()) {
    return false;
  } else {
    return !authService.userData()?.user?.is_active;
  }
};

export const notAuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const langService = inject(LanguageService);
  const router = inject(Router);

  if (authService.isLoggedIn()) {
    router.navigate([langService.currentLang() + '/' + Path.HOME]);
    return false;
  }
  return true;
};

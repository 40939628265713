<div class="user-info">
  <button type="button" (click)="showDropdown.set(!showDropdown())">
    <mars-avatar
      [fullName]="authService.userData()?.user?.name ?? ''"
      borderRadius="at-rounded-circle" />
  </button>

  @if (showDropdown()) {
    <div
      marsClickOutSide
      (clickOutside)="showDropdown.set(false)"
      class="user-dropdown at-p-md at-bg-white at-border at-border-neutral-200 at-radius-md at-flex at-flex-column at-gap-md">
      <div class="info at-flex at-flex-column at-gap-sm at-p-sm">
        <div class="user-info at-flex at-gap-md">
          <div class="avatar">
            <mars-avatar
              [fullName]="authService.userData()?.user?.name ?? ''"
              borderRadius="at-rounded-circle" />
          </div>

          <div class="text-info">
            <p
              class="name at-text-sm at-text-neutral-900 at-fw-semibold at-mb-x-sm at-word-break-all">
              {{ authService.userData()?.user?.name }}
            </p>
            <p class="email at-text-xs at-text-neutral-700 at-word-break-all">
              {{ authService.userData()?.user?.email }}
            </p>
          </div>
        </div>

        @if (userType) {
          <div class="user-type">
            <mars-badge
              [label]="userType"
              [badgeClass]="
                'at-text-xs at-fw-medium at-text-white at-px-sm ' +
                (userType === 'Buyer'
                  ? 'at-bg-buyer-500'
                  : 'at-bg-supplier-500')
              " />
          </div>
        }
      </div>

      <button
        type="button"
        [disabled]="isLoggingOut()"
        (click)="showDropdown.set(false); userLogout()">
        <div
          class="logout at-flex at-align-center at-gap-md at-py-md at-px-sm at-border-top at-border-neutral-200">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.5332 12.1833L16.6665 10.05L14.5332 7.91663"
              stroke="#111827"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M8.13379 10.05H16.6088"
              stroke="#111827"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M9.80046 16.6667C6.11712 16.6667 3.13379 14.1667 3.13379 10C3.13379 5.83337 6.11712 3.33337 9.80046 3.33337"
              stroke="#111827"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>

          <p class="at-text-xs at-text-neutral-900 at-fw-medium">Logout</p>
        </div>
      </button>
    </div>
  }
</div>

<mars-top-navbar />
<router-outlet></router-outlet>

@if (toastService.showToast()) {
  <mars-alert-inline
    class="toast"
    [alertData]="toastService.toasterData().alertData"
    [hasCloseIcon]="toastService.toasterData().hasCloseIcon"
    [hasIcon]="toastService.toasterData().hasIcon"
    (onAlertClose)="
      toastService.showToast.set(false); toastService.resetInterval()
    " />
}

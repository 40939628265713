import { Component, inject, signal } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LanguageService } from '../../services/language/language.service';
import { NavigationPipe } from '../../pipes/navigation/navigation.pipe';
import { AuthService } from '../../services/auth/auth.service';
import { Path } from '../../constants/paths.constant';
import { LogoComponent } from '../logo/logo.component';
import { MainService } from '../../services/main/main.service';
import { UserInfoComponent } from '../user-info/user-info.component';
import { StorageKey } from '../../constants/storage.constant';
import { ToasterService } from '@mars/ui-components';

@Component({
  selector: 'mars-top-navbar',
  imports: [
    TranslocoPipe,
    RouterLink,
    RouterLinkActive,
    NavigationPipe,
    LogoComponent,
    UserInfoComponent,
  ],
  templateUrl: './top-navbar.component.html',
  styleUrl: './top-navbar.component.scss',
})
export class TopNavbarComponent {
  private readonly languageService = inject(LanguageService);
  private readonly translocoService = inject(TranslocoService);
  protected readonly authService = inject(AuthService);
  protected readonly mainService = inject(MainService);
  private readonly toastService = inject(ToasterService);

  protected readonly Path = Path;

  lang = this.languageService.currentLang;
  isMobileNavbarOpened = false;

  navbarLinks = [
    {
      label: this.translocoService.translate('top_navbar_section_about_link'),
      path: Path.ABOUT,
    },
    {
      label: this.translocoService.translate(
        'top_navbar_section_features_link'
      ),
      path: Path.FEATURES,
    },
    {
      label: this.translocoService.translate(
        'top_navbar_section_become_a_supplier_link'
      ),
      path: Path.BECOME_SUPPLIER,
    },
    {
      label: this.translocoService.translate(
        'top_navbar_section_contact_us_link'
      ),
      path: Path.CONTACT_US,
    },
  ];

  isLoggingOut = signal(false);

  switchLanguage() {
    this.languageService.switchLanguage();
  }

  toggleMobileNavbar() {
    this.isMobileNavbarOpened = !this.isMobileNavbarOpened;
  }

  logout() {
    this.isLoggingOut.set(true);
    this.authService.logOut().subscribe({
      next: response => {
        if ((response.message as string).includes('successfully')) {
          this.toastService.add(response.message, '', '', 'success');
          this.authService.isLoggedIn.set(false);
          this.authService.userData.set(undefined);
          this.authService.userState$.next(undefined);
          localStorage.removeItem(StorageKey.TOKEN);
          localStorage.removeItem(StorageKey.USER);
        }
      },
      complete: () => this.isLoggingOut.set(false),
    });
  }
}

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MainService } from '../../common/services/main/main.service';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'mars-main',
  standalone: true,
  imports: [RouterOutlet, NgTemplateOutlet],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements OnInit, OnDestroy {
  protected readonly mainService = inject(MainService);

  ngOnInit(): void {
    this.mainService.showNavbarLinks.set(false);
  }

  ngOnDestroy(): void {
    this.mainService.showNavbarLinks.set(true);
  }
}

import { Path } from '../../constants/paths.constant';
import { UserState } from '../../constants/userStates.constant';
import { UserStateTypes } from '../../models/user';
import { RouteStrategy } from './route-stratgy.interface';

export class SupplierRouteStrategy implements RouteStrategy {
  title = 'Supplier';

  private availableRoutes = {
    '': [Path.WELCOME_TO_CONTRAA, Path.SUPPLIER_REGISTRATION],
    [UserState.DRAFT]: [Path.SUPPLIER_REGISTRATION],
    [UserState.PENDING_APPROVAL]: [Path.WAITING_FOR_APPROVAL],
    [UserState.APPROVE]: [Path.DASHBOARD],
    [UserState.REJECTED]: [Path.SUPPLIER_REGISTRATION],
  };

  isAllowed(
    url: string,
    userState: Exclude<UserStateTypes, null> | ''
  ): boolean {
    return this.availableRoutes[userState]?.includes(url) || false;
  }

  getRedirectPath(userState: string): string {
    if (userState === UserState.REJECTED || userState === UserState.DRAFT) {
      return Path.SUPPLIER_REGISTRATION + '/1';
    } else if (userState === '') {
      return Path.WELCOME_TO_CONTRAA;
    } else if (userState === UserState.PENDING_APPROVAL) {
      return Path.WAITING_FOR_APPROVAL;
    } else {
      return Path.DASHBOARD;
    }
  }
}

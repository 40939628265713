import { Component, input } from '@angular/core';

@Component({
  selector: 'mars-tab',
  imports: [],
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss',
})
export class TabComponent {
  tabTitle = input.required<string>();
  tabIcon = input<string>('');
  tabTag = input<string>('');
  disable = input<boolean>(false);
  active = false;
}

import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly transloco = inject(TranslocoService);
  private readonly router = inject(Router);

  private readonly lang = signal(localStorage.getItem('lang') ?? 'en');
  currentLang = this.lang.asReadonly();

  constructor() {
    this.setLanguageConditions();
  }

  switchLanguage() {
    this.lang.set(this.lang() === 'en' ? 'ar' : 'en');
    this.updateRoute();
    this.updateTransloco();
    this.setLangLocalStorage();
  }

  private setLanguageConditions() {
    const pathNameArray: string[] = location.pathname.split('/');
    const savedLang = localStorage.getItem('lang');

    if (pathNameArray[1].includes('en') || pathNameArray[1].includes('ar')) {
      this.lang.set(pathNameArray[1]);
    } else if (savedLang === 'en' || savedLang === 'ar') {
      this.lang.set(savedLang);
      this.updateRoute();
    } else {
      this.lang.set('en');
      this.updateRoute();
    }

    this.applyDirection();
  }

  private updateRoute() {
    const pathNameArray: string[] = location.pathname.split('/');
    const modifiedUrl = this.router.url.replace(
      `/${pathNameArray[1]}`,
      `/${this.lang()}`
    );
    document.location.replace(modifiedUrl);
  }

  private updateTransloco() {
    this.transloco.setActiveLang(this.lang());
    this.transloco.load(this.lang());
  }

  private applyDirection() {
    const htmlElement: HTMLHtmlElement =
      document.documentElement as HTMLHtmlElement;
    const bodyElement: HTMLBodyElement = document.body as HTMLBodyElement;
    htmlElement.lang = this.lang();
    if (this.lang() === 'ar') {
      htmlElement.dir = 'rtl';
      bodyElement.dir = 'rtl';
    } else {
      htmlElement.dir = 'ltr';
      bodyElement.dir = 'ltr';
    }
  }

  private setLangLocalStorage() {
    localStorage.setItem('lang', this.lang());
  }
}

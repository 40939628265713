import { Component } from '@angular/core';

@Component({
  selector: 'mars-logo',
  standalone: true,
  template: ` <svg
    width="133"
    height="41"
    viewBox="0 0 133 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4352_65680)">
      <path
        d="M6.37586 14.1818C5.53496 15.0425 5.07629 16.2944 5.07629 17.8201V23.1799C5.07629 24.197 5.26741 25.0969 5.68786 25.8402C6.07008 26.5835 6.64342 27.1703 7.33143 27.5615C8.01943 27.9528 8.82211 28.1093 9.70122 28.1093H19.2186V25.1751H9.96878C9.35722 25.1751 8.86033 25.0577 8.43988 24.7448C8.09588 24.51 7.90476 24.0014 7.90476 23.2581V17.6636C7.90476 16.9203 8.09588 16.4117 8.43988 16.177C8.86033 15.9032 9.35722 15.7467 9.96878 15.7467H19.2186V12.8907H9.70122C8.32521 12.8907 7.21676 13.3211 6.37586 14.1818Z"
        fill="#4720CD" />
      <path
        d="M35.1193 13.4776C34.4313 13.0864 33.6286 12.8907 32.7113 12.8907H26.5956C25.2196 12.8907 24.1112 13.3211 23.2321 14.1818C22.3912 15.0425 21.9325 16.2553 21.9325 17.8201V23.1407C21.9325 24.7056 22.3529 25.9184 23.2321 26.7791C24.073 27.6398 25.2196 28.0701 26.5956 28.0701H32.7113C34.0873 28.0701 35.1957 27.6398 36.0748 26.7791C36.954 25.9184 37.3744 24.7056 37.3744 23.1407V17.8201C37.3744 16.803 37.1833 15.9032 36.7629 15.1598C36.3806 14.4165 35.8073 13.8297 35.0811 13.4385L35.1193 13.4776ZM34.5842 17.7028V23.2972C34.5842 24.0406 34.3931 24.5491 34.049 24.7839C33.6286 25.0577 33.1317 25.2142 32.5201 25.2142H26.8632C26.2516 25.2142 25.7548 25.0577 25.3343 24.7839C24.9903 24.5491 24.7992 24.0406 24.7992 23.2972V17.7028C24.7992 16.9594 24.9903 16.4509 25.3343 16.2161C25.7548 15.9423 26.2516 15.7858 26.8632 15.7858H32.5201C33.1317 15.7858 33.6286 15.9423 34.049 16.2161C34.3931 16.4509 34.5842 16.9594 34.5842 17.7028Z"
        fill="#4720CD" />
      <path
        d="M55.3007 22.5148C55.3007 23.4928 55.186 24.2362 54.9185 24.6665C54.6891 25.0577 54.2687 25.2533 53.6571 25.2533C53.2749 25.2533 52.9691 25.1751 52.7016 25.0577C52.4722 24.9404 52.2811 24.7448 52.09 24.4709C51.8989 24.1579 51.7078 23.6885 51.4784 23.1016L49.6438 17.5463C49.338 16.5682 48.994 15.7467 48.65 15.1207C48.2677 14.4556 47.7709 13.9079 47.1593 13.4776C46.5477 13.0864 45.7451 12.8516 44.8277 12.8516C43.2988 12.8516 42.1139 13.282 41.3112 14.1818C40.5086 15.0425 40.1263 16.2552 40.1263 17.7028V28.1484H42.9548V18.6417C42.9548 18.0157 42.993 17.468 43.1077 17.0377C43.1841 16.6856 43.3753 16.3726 43.6428 16.1379C43.9104 15.9032 44.3308 15.7858 44.9042 15.7858C45.3628 15.7858 45.7451 15.9423 46.0126 16.2552C46.3184 16.6465 46.6242 17.1942 46.8535 17.9766L48.7646 23.7276C49.1086 24.7839 49.4909 25.6446 49.8731 26.2705C50.2553 26.9356 50.7904 27.4051 51.3638 27.718C51.9371 28.031 52.7016 28.1875 53.5807 28.1875C55.1096 28.1875 56.2563 27.718 57.0207 26.8573C57.7852 25.9967 58.1674 24.5883 58.1674 22.7104V12.8907H55.3389V22.5148H55.3007Z"
        fill="#4720CD" />
      <path
        d="M60.0786 15.8249H65.6973V28.1093H68.5258V15.8249H74.1063V12.8907H60.0786V15.8249Z"
        fill="#4720CD" />
      <path
        d="M85.917 22.0453H86.9872C88.2868 22.0453 89.3952 21.6541 90.1979 20.9499C91.0388 20.2066 91.4593 19.0329 91.4593 17.5072C91.4593 15.9814 91.0006 14.886 90.1597 14.0644C89.3188 13.282 88.1721 12.8907 86.8343 12.8907H80.6805C79.3045 12.8907 78.196 13.3211 77.3169 14.1818C76.476 15.0425 76.0173 16.2553 76.0173 17.8201V28.1093H78.8458V17.7028C78.8458 16.9594 79.0369 16.4509 79.3809 16.2161C79.8014 15.9423 80.2983 15.7858 80.9098 15.7858H86.6432C87.2165 15.7858 87.7134 15.9032 88.0957 16.177C88.4397 16.4117 88.5926 16.803 88.5926 17.468C88.5926 18.1331 88.4397 18.5635 88.1339 18.7591C87.7899 18.9938 87.3694 19.1112 86.9108 19.1112H85.6876C84.2734 19.1112 83.165 19.5024 82.4005 20.324C81.6361 21.1064 81.2156 22.2409 81.2156 23.6102C81.2156 25.0969 81.6743 26.2314 82.5534 26.9747C83.4325 27.718 84.5027 28.1093 85.8405 28.1093H91.2681V25.1751H86.2228C85.5348 25.1751 84.9996 25.0577 84.5792 24.7839C84.2352 24.5883 84.0441 24.1579 84.0441 23.5711C84.0441 22.9843 84.197 22.6322 84.4645 22.3974C84.7703 22.1627 85.2672 22.0062 85.8405 22.0062L85.917 22.0453Z"
        fill="#4720CD" />
      <path
        d="M127.35 15.199C126.968 14.4556 126.395 13.8688 125.669 13.4776C124.98 13.0864 124.178 12.8907 123.26 12.8907H117.068C115.692 12.8907 114.584 13.3211 113.705 14.1818C112.864 15.0425 112.405 16.2553 112.405 17.8201V28.1093H115.234V17.7028C115.234 16.9594 115.425 16.4509 115.769 16.2161C116.189 15.9423 116.686 15.7858 117.298 15.7858H123.031C123.643 15.7858 124.14 15.9423 124.56 16.2161C124.904 16.4509 125.095 16.9594 125.095 17.7028V28.1093H127.924V17.8593C127.924 16.8421 127.733 15.9423 127.312 15.199H127.35Z"
        fill="#4720CD" />
      <path
        d="M123.834 20.989H116.572V23.8841H123.834V20.989Z"
        fill="#4720CD" />
      <path
        d="M107.475 13.4776C106.787 13.0864 105.984 12.8907 105.067 12.8907H98.8745C97.4984 12.8907 96.39 13.3211 95.5109 14.1818C94.67 15.0425 94.2113 16.2553 94.2113 17.8201V28.1093H97.0398V17.7028C97.0398 16.9594 97.2309 16.4509 97.5749 16.2161C97.9953 15.9423 98.4922 15.7858 99.1038 15.7858H104.837C105.449 15.7858 105.946 15.9423 106.366 16.2161C106.71 16.4509 106.901 16.9594 106.901 17.7028V28.1093H109.73V17.8593C109.73 16.8421 109.539 15.9423 109.118 15.199C108.736 14.4556 108.163 13.8688 107.436 13.4776H107.475Z"
        fill="#4720CD" />
      <path d="M105.64 20.989H98.3776V23.8841H105.64V20.989Z" fill="#4720CD" />
    </g>
    <defs>
      <clipPath id="clip0_4352_65680">
        <rect
          width="122.847"
          height="15.375"
          fill="white"
          transform="translate(5.07629 12.8125)" />
      </clipPath>
    </defs>
  </svg>`,
})
export class LogoComponent {}

@if (isShownAlert) {
  <div
    [class.normal]="alertData().status === 'normal'"
    [class.primary]="alertData().status === 'primary'"
    [class.warning]="alertData().status === 'warning'"
    [class.success]="alertData().status === 'success'"
    [class.error]="alertData().status === 'error'"
    class="alert alert-border at-my-4xl">
    <div class="at-flex at-justify-between">
      <div class="info at-flex at-align-start">
        @if (hasIcon()) {
          <div class="icon">
            <img
              [src]="
                alertData().icon ||
                'assets/icons/heart/' + alertData().status + '.svg'
              "
              alt="icon" />
          </div>
        }

        <div>
          <p class="at-text-sm at-fw-medium">
            {{ alertData().title }}
          </p>

          <p class="desc at-text-sm">
            {{ alertData().description }}
          </p>

          <div class="content">
            <ng-content />
          </div>
        </div>
      </div>

      @if (hasCloseIcon()) {
        <div class="close-icon at-ms-sm">
          <button class="" (click)="closeAlert()">
            @switch (alertData().status) {
              @case ('error') {
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M5.00098 5L19 18.9991"
                    stroke="#EF4444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M5.00006 18.9991L18.9991 5"
                    stroke="#EF4444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              }

              @case ('success') {
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M5.00098 5L19 18.9991"
                    stroke="#22C55E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M5.00006 18.9991L18.9991 5"
                    stroke="#22C55E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              }

              @case ('warning') {
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M5.00098 5L19 18.9991"
                    stroke="#F59E0B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M5.00006 18.9991L18.9991 5"
                    stroke="#F59E0B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              }

              @case ('primary') {
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M5.00098 5L19 18.9991"
                    stroke="#4B4EFC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M5.00006 18.9991L18.9991 5"
                    stroke="#4B4EFC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              }

              @case ('normal') {
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M5.00098 5L19 18.9991"
                    stroke="#111827"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M5.00006 18.9991L18.9991 5"
                    stroke="#111827"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              }
            }
          </button>
        </div>
      }
    </div>
  </div>
}

<div class="modal-container" [ngClass]="{ active: modal().isOpen }">
  <div class="modal at-bg-white at-shadow-lg at-p-2xl">
    @if (modal().hasCloseIcon) {
      <div class="at-flex at-justify-between at-mb-6xl">
        @if (modal().hasLikeIcon) {
          <button
            type="button"
            class="like-icon at-bg-primary-50 at-rounded-circle at-flex at-justify-center at-align-center">
            <img src="assets/icons/heart/primary.svg" />
          </button>
        }

        @if (modal().hasCloseIcon) {
          <button type="button" (click)="closeModal()">
            <img src="assets/ui-components/close.svg" />
          </button>
        }
      </div>
    }

    @if (modal().title) {
      <h2 class="at-mb-sm at-text-lg">{{ modal().title }}</h2>
    }

    @if (modal().description) {
      <p class="at-normal at-text-neutral-500">
        {{ modal().description }}
      </p>
    }

    @if (modal() && modal().customizeContent) {
      <ng-container [ngTemplateOutlet]="modal().customizeContent" />
    }
  </div>
</div>

<div
  class="overlay"
  [ngClass]="{ active: modal().isOpen }"
  (click)="closeModal()"></div>

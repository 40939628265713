import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpBaseService {
  private readonly httpClient = inject(HttpClient);

  get(url: string) {
    return this.httpClient.get(url).pipe(
      map(response => response),
      catchError(error => {
        return of(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post(url: string, body: any) {
    return this.httpClient.post(url, body).pipe(
      map(response => response),
      catchError(error => {
        return of(error);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put(url: string, body: any) {
    return this.httpClient.put(url, body).pipe(
      map(response => response),
      catchError(error => {
        return of(error);
      })
    );
  }

  delete(url: string) {
    return this.httpClient.delete(url).pipe(
      map(response => response),
      catchError(error => {
        return of(error);
      })
    );
  }
}

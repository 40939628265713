import { Component } from '@angular/core';
import { NavigationPipe } from '../../pipes/navigation/navigation.pipe';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { Path } from '../../constants/paths.constant';
import { LogoComponent } from '../logo/logo.component';

@Component({
  selector: 'mars-footer',
  imports: [
    NavigationPipe,
    RouterLink,
    RouterLinkActive,
    TranslocoModule,
    LogoComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  protected readonly Path = Path;
}

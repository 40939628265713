import { Injectable, signal } from '@angular/core';
import { ENDPOINTS } from '../../constants/ENDPOINTS';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  UserRegisterData,
  UserRegisterResponse,
  UserState,
} from '../../models/user';
import { StorageKey } from '../../constants/storage.constant';
import { HttpBaseService } from '../httpBase/httpBase.service';
import { CountryResponse } from '../../models/countries';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends HttpBaseService {
  isLoggedIn = signal(false);
  userData = signal<UserRegisterResponse | undefined>(undefined);
  userState$ = new BehaviorSubject<UserState | undefined>(undefined);

  constructor() {
    super();

    const isLogged = localStorage.getItem(StorageKey.TOKEN);
    const isUser = localStorage.getItem(StorageKey.USER);

    if (isLogged) {
      this.isLoggedIn.set(true);
    }

    if (isUser) {
      this.userData.set(JSON.parse(isUser));
      this.userState$.next(this.userData()?.user.state);
    }
  }

  login(userData: {
    email: string;
    password: string;
  }): Observable<UserRegisterResponse> {
    const url = ENDPOINTS.POST_USER_LOGIN;
    return this.post(url, userData) as Observable<UserRegisterResponse>;
  }

  logOut() {
    const url = ENDPOINTS.POST_LOGOUT;
    return this.post(url, {});
  }

  register(userData: UserRegisterData): Observable<UserRegisterResponse> {
    const url = ENDPOINTS.POST_USER_REGISTRATION;
    return this.post(url, userData) as Observable<UserRegisterResponse>;
  }

  getCountriesPhoneCodes(): Observable<CountryResponse> {
    const url = ENDPOINTS.GET_COUNTRIES;
    return this.get(url);
  }
}

import { Component, input } from '@angular/core';
import { AvatarPipe } from '../../pipes/avatar.pipe';

@Component({
  selector: 'mars-avatar',
  standalone: true,
  imports: [AvatarPipe],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
  fullName = input<string>('');
  img = input<string>('');
  size = input<'list' | 'card' | 'profile' | null>(null);
  borderRadius = input<string>('');
  imgDimensions = input<{ width: string; height: string }>({
    width: '',
    height: '',
  });
}

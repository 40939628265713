<footer class="at-py-7xl">
  <div class="at-px-lg md:at-px-6xl">
    <div
      class="top-section at-flex at-flex-column at-justify-between at-border-bottom at-border-neutral-300 at-mb-2xl">
      <div class="at-mb-2xl">
        <!-- Logo -->
        <div class="svg-container">
          <mars-logo />
        </div>

        <p class="at-text-sm at-text-neutral-500">
          {{ 'footer_section_contraa_slogan' | transloco }}
        </p>

        <div class="social-links at-py-xl at-mt-lg">
          <ul class="at-flex at-gap-md">
            <!-- Facebook -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3054_5300)">
                    <path
                      d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                      fill="#111827" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3054_5300">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>

            <!-- Linkedin -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3054_5301)">
                    <path
                      d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
                      fill="#111827" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3054_5301">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>

            <!-- Tiktok -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.0725 0H13.0278V16.3478C13.0278 18.2957 11.4722 19.8957 9.53626 19.8957C7.60034 19.8957 6.04469 18.2957 6.04469 16.3478C6.04469 14.4348 7.56577 12.8695 9.43257 12.8V8.69567C5.31872 8.7652 2 12.1391 2 16.3478C2 20.5913 5.38786 24 9.57085 24C13.7538 24 17.1416 20.5565 17.1416 16.3478V7.9652C18.6627 9.07827 20.5295 9.73913 22.5 9.77393V5.66957C19.4579 5.56522 17.0725 3.06087 17.0725 0Z"
                    fill="#111827" />
                </svg>
              </a>
            </li>

            <!-- Youtube -->
            <li>
              <a href="#">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.7609 7.1998C23.7609 7.1998 23.5266 5.54512 22.8047 4.81855C21.8906 3.8623 20.8688 3.85762 20.4 3.80137C17.0438 3.55762 12.0047 3.55762 12.0047 3.55762H11.9953C11.9953 3.55762 6.95625 3.55762 3.6 3.80137C3.13125 3.85762 2.10938 3.8623 1.19531 4.81855C0.473438 5.54512 0.24375 7.1998 0.24375 7.1998C0.24375 7.1998 0 9.14512 0 11.0857V12.9045C0 14.8451 0.239062 16.7904 0.239062 16.7904C0.239062 16.7904 0.473437 18.4451 1.19062 19.1717C2.10469 20.1279 3.30469 20.0951 3.83906 20.1982C5.76094 20.3811 12 20.4373 12 20.4373C12 20.4373 17.0438 20.4279 20.4 20.1889C20.8688 20.1326 21.8906 20.1279 22.8047 19.1717C23.5266 18.4451 23.7609 16.7904 23.7609 16.7904C23.7609 16.7904 24 14.8498 24 12.9045V11.0857C24 9.14512 23.7609 7.1998 23.7609 7.1998ZM9.52031 15.1123V8.36699L16.0031 11.7514L9.52031 15.1123Z"
                    fill="#111827" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="links at-mb-2xl">
        <a
          [routerLink]="'/' + (Path.ABOUT | navigation)"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_about_link' | transloco }}</a
        >
        <a
          [routerLink]="'/' + (Path.BECOME_SUPPLIER | navigation)"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_become_a_supplier_link' | transloco }}</a
        >
        <a
          [routerLink]="'/' + (Path.FEATURES | navigation)"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_features_link' | transloco }}</a
        >
        <a
          [routerLink]="'/' + (Path.CONTACT_US | navigation)"
          routerLinkActive="active-route"
          class="at-py-x-sm at-fw-medium at-text-neutral-900 at-text-sm"
          >{{ 'footer_section_contact_us_link' | transloco }}</a
        >
      </div>
    </div>

    <div
      class="bottom-section at-flex at-flex-column at-justify-between at-gap-4xl">
      <div class="at-flex at-gap-lg">
        <a
          class="at-py-x-sm at-text-sm at-fw-medium at-text-neutral-900"
          href="#"
          >{{ 'footer_section_privacy_policy_link' | transloco }}</a
        >
        <a
          class="at-py-x-sm at-text-sm at-fw-medium at-text-neutral-900"
          href="#"
          >{{ 'footer_section_terms_conditions_link' | transloco }}</a
        >
      </div>
      <p class="at-py-x-sm at-text-neutral-700 at-text-x-sm">
        {{ 'footer_section_developed_by_link' | transloco }}
        <a
          class="at-fw-semibold at-text-supplier-500"
          href="https://www.areebtechnology.com/"
          target="_blank"
          >Areeb Technology</a
        >
      </p>
    </div>
  </div>
</footer>

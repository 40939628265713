import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertData } from './alertData.interface';

@Component({
  selector: 'mars-alert-inline',
  imports: [CommonModule],
  templateUrl: './alert-inline.component.html',
  styleUrl: './alert-inline.component.scss',
})
export class AlertInlineComponent {
  alertData = input.required<AlertData>();
  hasCloseIcon = input<boolean>(true);
  hasIcon = input<boolean>(false);
  protected isShownAlert = true;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onAlertClose = output<boolean>();

  protected closeAlert() {
    this.isShownAlert = false;
    this.onAlertClose.emit(false);
  }
}

import { Path } from '../../constants/paths.constant';
import { UserState } from '../../constants/userStates.constant';
import { UserStateTypes } from '../../models/user';
import { RouteStrategy } from './route-stratgy.interface';

export class UserRouteStrategy implements RouteStrategy {
  title = null;

  private availableRoutes = {
    '': [
      Path.WELCOME_TO_CONTRAA,
      Path.BUYER_REGISTRATION,
      Path.SUPPLIER_REGISTRATION,
    ],
    [UserState.DRAFT]: [Path.WELCOME_TO_CONTRAA],
    [UserState.PENDING_APPROVAL]: [Path.WELCOME_TO_CONTRAA],
    [UserState.APPROVE]: [Path.WELCOME_TO_CONTRAA],
    [UserState.REJECTED]: [Path.WELCOME_TO_CONTRAA],
  };

  isAllowed(
    url: string,
    userState: Exclude<UserStateTypes, null> | ''
  ): boolean {
    return this.availableRoutes[userState]?.includes(url) || false;
  }

  getRedirectPath(): string {
    return Path.WELCOME_TO_CONTRAA;
  }
}

<nav class="at-w-100 at-flex at-justify-between at-py-lg at-px-6xl at-bg-white">
  <a class="at-no-decoration" id="logo" [routerLink]="'/'">
    <span class="at-h6 at-text-neutral-900"> DIB </span>
  </a>

  <div>
    <a class="">
      <img src="assets/images/icons/notification.svg" alt="notification" />
    </a>
  </div>
</nav>

import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { environment } from '.././environments/environment';
import * as Sentry from '@sentry/angular';
import ar from '@angular/common/locales/ar';
import { registerLocaleData } from '@angular/common';
import { authInterceptor } from './common/interceptors/auth.interceptor';
import { errorInterceptor } from './common/interceptors/error.interceptor';
registerLocaleData(ar);

const pathNameArray: string[] = location.pathname.split('/');
const savedLang: string = localStorage.getItem('lang') ?? 'en';

if (pathNameArray[1].includes('en') || pathNameArray[1].includes('ar')) {
  localStorage.setItem('lang', pathNameArray[1]);
} else if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', 'en');
}

export const INITIAL_LANGUAGE: string =
  pathNameArray[1].includes('en') || pathNameArray[1].includes('ar')
    ? pathNameArray[1]
    : savedLang;

export function preloadTranslation(transloco: TranslocoService) {
  return function () {
    transloco.setActiveLang(INITIAL_LANGUAGE);
    return transloco.load(INITIAL_LANGUAGE);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([authInterceptor, errorInterceptor])
    ),
    provideTransloco({
      config: {
        availableLangs: ['en', 'ar'],
        defaultLang: INITIAL_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: preloadTranslation,
      deps: [TranslocoService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
};

import { Route } from '@angular/router';
import { HomeComponent } from './pages/landing/home/home.component';
import { LandingComponent } from './layout/landing/landing.component';
import { authGuard, notAuthGuard } from './common/guards/auth.guard';
import { DeactivateGuard } from './common/guards/Deactivate.guard';
import { Path } from './common/constants/paths.constant';
import { MainComponent } from './layout/main/main.component';
import { userStateGuard } from './common/guards/userState.guard';

export const appRoutes: Route[] = [
  {
    path: `:lang`,
    children: [
      /* Landing page routes (routes include footer) */
      {
        path: '',
        component: LandingComponent,
        children: [
          {
            path: Path.HOME,
            component: HomeComponent,
          },
          {
            path: Path.ABOUT,
            loadComponent: () =>
              import('./pages/landing/about/about.component').then(
                c => c.AboutComponent
              ),
          },
          {
            path: Path.FEATURES,
            loadComponent: () =>
              import('./pages/landing/features/features.component').then(
                c => c.FeaturesComponent
              ),
          },
          {
            path: Path.BECOME_SUPPLIER,
            loadComponent: () =>
              import(
                './pages/landing/become-supplier/become-supplier.component'
              ).then(c => c.BecomeSupplierComponent),
          },
          {
            path: Path.CONTACT_US,
            loadComponent: () =>
              import('./pages/landing/contact-us/contact-us.component').then(
                c => c.ContactUsComponent
              ),
          },
          {
            path: '',
            redirectTo:
              '/' + (localStorage.getItem('lang') ?? 'en') + '/' + Path.HOME,
            pathMatch: 'full',
          },
        ],
      },

      {
        path: '',
        component: MainComponent,
        children: [
          /* Add routes of Authenticated users only here */
          {
            path: '',
            canActivate: [authGuard],
            children: [
              {
                path: Path.DASHBOARD,
                loadComponent: () =>
                  import('./pages/dashboard/dashboard.component').then(
                    c => c.DashboardComponent
                  ),
                canActivate: [userStateGuard],
              },
              {
                path: Path.WAITING_FOR_APPROVAL,

                loadComponent: () =>
                  import(
                    './pages/user/waiting-approval/waiting-approval.component'
                  ).then(c => c.WaitingApprovalComponent),
                canDeactivate: [DeactivateGuard],
              },
              {
                path: Path.SUPPLIER_REGISTRATION + '/:step',
                loadComponent: () =>
                  import(
                    './pages/user/supplier-register/supplier-register.component'
                  ).then(c => c.SupplierRegisterComponent),
                canActivate: [userStateGuard],
              },
              {
                path: Path.BUYER_REGISTRATION,
                loadComponent: () =>
                  import(
                    './pages/user/buyer-register/buyer-register.component'
                  ).then(c => c.BuyerRegisterComponent),
                canActivate: [userStateGuard],
              },
              {
                path: Path.WELCOME_TO_CONTRAA,
                loadComponent: () =>
                  import(
                    './pages/user/complete-profile-confirmation/complete-profile-confirmation.component'
                  ).then(c => c.CompleteProfileConfirmationComponent),
                canActivate: [userStateGuard],
              },
            ],
          },
          {
            path: '',
            canActivate: [notAuthGuard],
            children: [
              {
                path: Path.SIGN_UP,
                loadComponent: () =>
                  import('./pages/auth/sign-up/sign-up.component').then(
                    c => c.SignUpComponent
                  ),
              },
              {
                path: Path.LOGIN,
                loadComponent: () =>
                  import('./pages/auth/login/login.component').then(
                    c => c.LoginComponent
                  ),
              },
            ],
          },
        ],
      },

      {
        path: 'presentation',
        loadComponent: () =>
          import('./pages/presentation/presentation.component').then(
            m => m.PresentationComponent
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/' + (localStorage.getItem('lang') ?? 'en') + '/home',
    pathMatch: 'full',
  },
];

import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[marsBack]',
})
export class BackActionDirective {
  @HostListener('click')
  onClick() {
    history.back();
  }
}

@if (img()) {
  <div class="at-flex at-align-center at-justify-center">
    <img
      [src]="img()"
      [class]="[size() ? size() : '', borderRadius() ? borderRadius() : '']"
      alt=""
      [style]="
        !size()
          ? {
              width: imgDimensions().width + 'px',
              height: imgDimensions().height + 'px',
            }
          : {}
      " />
  </div>
} @else if (fullName()) {
  <div
    class="at-flex at-align-center at-justify-center at-bg-supplier-50 at-w-100 at-h-100"
    [class]="[size() ? size() : '', borderRadius() ? borderRadius() : '']"
    [style]="
      !size()
        ? {
            width: imgDimensions().width + 'px',
            height: imgDimensions().height + 'px',
          }
        : {}
    ">
    <span class="at-text-xs at-text-supplier-500 at-fw-medium">
      {{ fullName() | avatar }}
    </span>
  </div>
}

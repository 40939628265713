<section class="at-bg-primary-500">
  <div
    class="at-px-lg md:at-px-6xl container at-flex at-flex-column at-justify-between at-py-6xl">
    <div class="at-text-white at-mb-4xl">
      <p class="at-text-md at-fw-medium at-mb-lg">
        {{ 'contact_us_screen_title' | transloco }}
      </p>
      <h4 class="at-h6 at-fw-bold at-mb-lg">
        {{ 'contact_us_screen_description' | transloco }}
      </h4>

      <div class="at-flex at-gap-lg at-align-center at-py-sm">
        <div class="svg-container">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.667 4.6665H9.33366C5.33366 4.6665 2.66699 6.6665 2.66699 11.3332V20.6665C2.66699 25.3332 5.33366 27.3332 9.33366 27.3332H22.667C26.667 27.3332 29.3337 25.3332 29.3337 20.6665V11.3332C29.3337 6.6665 26.667 4.6665 22.667 4.6665ZM23.2937 12.7865L19.1203 16.1198C18.2403 16.8265 17.1203 17.1732 16.0003 17.1732C14.8803 17.1732 13.747 16.8265 12.8803 16.1198L8.70699 12.7865C8.28033 12.4398 8.21366 11.7998 8.54699 11.3732C8.89366 10.9465 9.52033 10.8665 9.94699 11.2132L14.1203 14.5465C15.1337 15.3598 16.8537 15.3598 17.867 14.5465L22.0403 11.2132C22.467 10.8665 23.107 10.9332 23.4403 11.3732C23.787 11.7998 23.7203 12.4398 23.2937 12.7865Z"
              fill="white" />
          </svg>
        </div>

        <div>
          <p class="at-text-xs">
            {{ 'contact_us_screen_send_email_text' | transloco }}
          </p>
          <p class="at-text-md at-fw-medium">info&#64;contraa.com</p>
        </div>
      </div>

      <div class="at-flex at-gap-lg at-align-center at-py-sm">
        <div class="svg-container">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3054_5195)">
              <path
                d="M27.4935 11.2668C26.0935 5.10683 20.7201 2.3335 16.0001 2.3335C16.0001 2.3335 16.0001 2.3335 15.9868 2.3335C11.2801 2.3335 5.89346 5.0935 4.49346 11.2535C2.93346 18.1335 7.14679 23.9602 10.9601 27.6268C12.3735 28.9868 14.1868 29.6668 16.0001 29.6668C17.8135 29.6668 19.6268 28.9868 21.0268 27.6268C24.8401 23.9602 29.0535 18.1468 27.4935 11.2668ZM16.0001 17.9468C13.6801 17.9468 11.8001 16.0668 11.8001 13.7468C11.8001 11.4268 13.6801 9.54683 16.0001 9.54683C18.3201 9.54683 20.2001 11.4268 20.2001 13.7468C20.2001 16.0668 18.3201 17.9468 16.0001 17.9468Z"
                fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_3054_5195">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div>
          <p class="at-text-xs">
            {{ 'contact_us_screen_visit_in_person_text' | transloco }}
          </p>
          <p class="at-text-md at-fw-medium">OurStreet 1257/18, OurCity</p>
        </div>
      </div>
    </div>

    <div class="form-container at-bg-white at-radius-xxl at-p-4xl">
      <form [formGroup]="form">
        <div class="at-mb-2xl">
          <label class="at-text-sm at-fw-medium at-mb-x-sm" for="name"
            >{{ 'contact_us_screen_form_name_field_title' | transloco }}*</label
          >
          <mars-input
            inputFormControlName="name"
            [id]="'name'"
            type="text"
            name="name"
            [placeholder]="
              'contact_us_screen_form_name_field_placeholder' | transloco
            " />
        </div>

        <div class="at-mb-2xl">
          <label class="at-text-sm at-fw-medium at-mb-x-sm" for="email"
            >{{
              'contact_us_screen_form_email_field_title' | transloco
            }}*</label
          >
          <mars-input
            inputFormControlName="email"
            [id]="'email'"
            type="email"
            name="email"
            [placeholder]="
              'contact_us_screen_form_email_field_placeholder' | transloco
            " />
        </div>

        <div class="at-mb-2xl">
          <label class="at-text-sm at-fw-medium at-mb-x-sm" for="phone"
            >{{
              'contact_us_screen_form_phone_field_title' | transloco
            }}*</label
          >
          <mars-input
            inputFormControlName="phone"
            [id]="'phone'"
            type="phone"
            name="phone"
            [placeholder]="
              'contact_us_screen_form_phone_field_placeholder' | transloco
            " />
        </div>

        <div class="at-mb-4xl">
          <label class="at-text-sm at-fw-medium at-mb-x-sm" for="message">{{
            'contact_us_screen_form_message_field_title' | transloco
          }}</label>
          <mars-textarea
            inputFormControlName="message"
            [id]="'message'"
            type="message"
            name="message"
            [placeholder]="
              'contact_us_screen_form_message_field_placeholder' | transloco
            "
            [rows]="4" />
        </div>

        <button
          type="submit"
          [disabled]="form.invalid"
          class="at-text-lg at-fw-medium at-py-md at-px-2xl at-text-center at-radius-sm at-bg-primary-500 at-text-white at-w-100">
          {{ 'contact_us_screen_form_submit_button_label' | transloco }}
        </button>
      </form>
    </div>
  </div>
</section>

import { Component, input } from '@angular/core';
import { BadgeComponent } from '@mars/ui-components';

@Component({
  selector: 'mars-landing-card-img',
  standalone: true,
  imports: [BadgeComponent],
  templateUrl: './landing-card-img.component.html',
  styleUrl: './landing-card-img.component.scss',
})
export class LandingCardImgComponent {
  image = input.required<string>();
  title = input<string>();
  description = input<string>();
  position = input<'right' | 'left'>('right');
  customClasses = input<string>();
  badgeLabel = input<string>('');
}

@if (label()) {
<label [for]="id()" class="at-text-supplier-500 at-fw-medium at-mb-x-sm">{{
  label()
  }}</label>
}

<div class="wrapper at-position-relative">
  <div class="at-flex at-justify-between at-align-center at-border at-border-supplier-200 at-radius-sm at-p-md">
    <p [class]="{ 'at-text-neutral-900': files() && files().length }"
      class="placeholder at-text-sm at-text-neutral-500">
      @if (files() && files().length) {
      {{ files().length }} File Attached
      } @else {
      Click the icon to upload files
      }
    </p>

    <button type="button" class="icon">
      @if (files() && files().length) {
      <div [class]="{
            rotate: isFileListOpen(),
            'reset-rotate': !isFileListOpen(),
          }" class="arrow" (click)="isFileListOpen.set(!isFileListOpen())">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
            stroke="#4720CD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      } @else {
      <div (click)="isFileUploadBoxOpen.set(true); isFileListOpen.set(false)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 17V11L7 13" stroke="#4720CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9 11L11 13" stroke="#4720CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#4720CD" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#4720CD" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      }
    </button>
  </div>

  @if (isFileListOpen() && files() && files().length) {
  <div marsClickOutSide (clickOutside)="isFileListOpen.set(false)"
    class="files-dropdown at-position-absolute at-w-100 at-bg-neutral-50 at-py-2xl">
    <div class="files">
      @for (file of files(); track $index; let i = $index) {
      <div class="at-px-2xl">
        <mars-file-card [isDeleting]="file.deleting ?? false" [progress_percentage]="file.uploading ?? 0"
          [errorMessage]="file.error ?? ''" [showSize]="false" [uploadedFile]="file.uploadedFile" [file]="file.file"
          (delete)="removeFile(i, file.id)" [showProgress]="false" [downloadable]="true"
          [isDownloading]="file.downloading ?? false" (download)="downloadFile(file.id, i)" (retry)="reuploadFile(i)"
          (cancelUploading)="cancelUploading(i)" />
      </div>
      }
      <div class="at-mt-lg at-position-relative">
        <mars-bottom-upload-option [disable]="files().length >= uploadedFileLimit()" />
        <input [id]="id()" [name]="name()" [accept]="accept()" [multiple]="multiple()" (change)="change($event)"
          [disabled]="disabled() || files().length === uploadedFileLimit()" type="file" title="" [class]="{
              'at-cursor-not-allowed':
                disabled() || files().length >= uploadedFileLimit(),
            }" class="at-position-absolute at-h-100 at-w-100" />
      </div>
    </div>
  </div>
  }
</div>

@if (isFileUploadBoxOpen()) {
<div class="upload-file at-flex at-justify-center at-align-center">
  <div class="files-box at-position-relative at-bg-white at-radius-md">
    <h6
      class="header at-border-bottom at-border-supplier-200 at-fw-semibold at-text-neutral-900 at-h6 at-px-2xl at-py-xl">
      File Upload
    </h6>

    <div class="body at-my-2xl at-px-2xl at-flex at-flex-column at-gap-2xl">
      <div class="at-flex at-gap-2xl">
        <div class="upload-options at-bg-neutral-50 at-p-x-sm at-flex at-flex-column at-gap-md">
          <!-- To be list handled from ts -->
          <!-- Options icons -->
          <div title="Desktop"
            class="option-icon at-cursor-pointer at-flex at-justify-center at-align-center at-border at-border-supplier-500 at-radius-sm at-bg-white at-p-sm">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4"
                d="M18.3084 5.34175V10.7584H1.66675V5.34175C1.66675 3.31675 3.31675 1.66675 5.34175 1.66675H14.6334C16.6584 1.66675 18.3084 3.31675 18.3084 5.34175Z"
                fill="#4720CD" />
              <path
                d="M1.66675 10.7666V10.9333C1.66675 12.9666 3.31675 14.6083 5.34175 14.6083H8.54175C9.00008 14.6083 9.37508 14.9833 9.37508 15.4416V16.2499C9.37508 16.7083 9.00008 17.0833 8.54175 17.0833H6.52508C6.18341 17.0833 5.90008 17.3666 5.90008 17.7083C5.90008 18.0499 6.17508 18.3333 6.52508 18.3333H13.4834C13.8251 18.3333 14.1084 18.0499 14.1084 17.7083C14.1084 17.3666 13.8251 17.0833 13.4834 17.0833H11.4667C11.0084 17.0833 10.6334 16.7083 10.6334 16.2499V15.4416C10.6334 14.9833 11.0084 14.6083 11.4667 14.6083H14.6417C16.6751 14.6083 18.3167 12.9583 18.3167 10.9333V10.7666H1.66675Z"
                fill="#4720CD" />
            </svg>
          </div>
        </div>

        <!-- Input -->
        <div
          class="upload-input at-bg-neutral-50 at-position-relative at-border at-border-supplier-100 at-radius-md at-flex-grow">
          <!-- Files List -->
          @if (files() && files().length) {
          <div class="at-flex at-flex-column at-justify-between at-h-100">
            <div class="files at-py-2xl at-px-lg">
              @for (file of files(); track $index; let i = $index) {
              <mars-file-card [isDeleting]="file.deleting ?? false" [file]="file.file"
                [progress_percentage]="file.uploading ?? 0" [errorMessage]="file.error ?? ''"
                (delete)="removeFile(i, file.id, fileInput)" (retry)="reuploadFile(i)"
                (cancelUploading)="cancelUploading(i)" />
              }
            </div>

            <mars-bottom-upload-option [disable]="files().length >= uploadedFileLimit()" />
          </div>
          } @else {
          <div
            class="action at-h-100 at-w-100 at-py-2xl at-px-lg at-flex at-flex-column at-gap-lg at-justify-center at-align-center">
            <p class="at-text-neutral-900 at-text-sm at-fw-semibold">
              Drag & drop files here
            </p>
            <p class="at-text-neutral-500 at-text-xs">or</p>
            <button type="button"
              class="at-py-x-sm at-px-sm at-bg-supplier-500 at-text-white at-fw-medium at-radius-sm">
              Upload from computer
            </button>
          </div>
          }

          <!-- File Input -->
          <input #fileInput [id]="id()" [name]="name()" [accept]="accept()" [multiple]="multiple()"
            (change)="change($event)" [disabled]="
                disabled() ||
                (files() && files().length === uploadedFileLimit())
              " type="file" title="" [class]="{
                'at-cursor-not-allowed':
                  disabled() ||
                  (files() && files().length >= uploadedFileLimit()),
              }" class="at-position-absolute at-h-100 at-w-100" />

          <input [formControlName]="inputFormControlName()" hidden />
        </div>
      </div>

      <div class="validtions at-ps-7xl at-text-neutral-500 at-text-xs at-flex at-flex-column at-gap-x-sm">
        <p>{{ acceptedFormats() }}</p>
        <p>Max size {{ fileSizeLimit() | fileSize: ' ' }}</p>
        <p>
          Max files limit {{ uploadedFileLimit() + ' file'
          }}{{ uploadedFileLimit() > 1 ? 's' : '' }}
        </p>
      </div>
    </div>

    <!-- Buttons -->
    <div class="action at-border-top at-border-supplier-200 at-py-md at-px-2xl at-flex at-justify-end">
      <div class="action-btns at-flex at-gap-xl">
        <button type="button" (click)="
              childControl.setValue([]);
              files.set([]);
              isFileUploadBoxOpen.set(false)
            "
          class="at-px-2xl at-py-md at-fw-medium at-text-neutral-700 at-bg-white at-border at-border-supplier-200 at-radius-sm">
          Cancel
        </button>
        <button type="button" [disabled]="
              !files() ||
              !files().length ||
              !this.childControl.value ||
              !this.childControl.value?.length
            " (click)="continueProcess(); isFileUploadBoxOpen.set(false)"
          class="continue-btn at-px-2xl at-py-md at-fw-medium at-text-white at-bg-supplier-500 at-radius-sm">
          Continue
        </button>
      </div>
    </div>
  </div>
  <div (click)="
        childControl.setValue([]);
        files.set([]);
        isFileUploadBoxOpen.set(false)
      " class="overlay at-bg-black"></div>
</div>
}
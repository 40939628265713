<div class="main">
  <div class="container">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>

  @if (mainService.bottomSectionTemplate()) {
    <div class="bottom-actions">
      <div class="content">
        <ng-container
          [ngTemplateOutlet]="mainService.bottomSectionTemplate()" />
      </div>
    </div>
  }
</div>

import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Path } from '../constants/paths.constant';
import { ToasterService } from '@mars/ui-components';
import { NavigationPipe } from '../pipes/navigation/navigation.pipe';
import { AuthService } from '../services/auth/auth.service';
import { StorageKey } from '../constants/storage.constant';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const navigationPipe = new NavigationPipe();
  const authService = inject(AuthService);
  const toastService = inject(ToasterService);

  return next(req).pipe(
    catchError((httpError: HttpErrorResponse) => {
      const error = httpError.error;
      if (error?.type == 'un_authenticated') {
        toastService.add(
          '',
          error.message,
          'assets/icons/danger.svg',
          'error',
          true,
          false
        );

        authService.isLoggedIn.set(false);
        authService.userData.set(undefined);
        authService.userState$.next(undefined);
        localStorage.removeItem(StorageKey.TOKEN);
        localStorage.removeItem(StorageKey.USER);

        router.navigate(['/' + navigationPipe.transform(Path.LOGIN)]);
      } else if (httpError.status == 403) {
        toastService.add('', error.message, 'assets/icons/danger.svg', 'error');
      } else if (httpError.status != 422) {
        toastService.add(
          '',
          error.message,
          'assets/icons/danger.svg',
          'error',
          true,
          false
        );
      }
      return throwError(() => error);
    })
  );
};

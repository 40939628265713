import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
  forwardRef,
  input,
  output,
} from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'mars-tabs',
  imports: [CommonModule],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(forwardRef(() => TabComponent), { descendants: true })
  tabs!: QueryList<TabComponent>;
  tabStyle = input<string>('');
  tabPill = input<boolean>(false);
  tabSelected = output<number>();

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter(tab => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first, 0);
    }
  }

  selectTab(tab: TabComponent, index: number) {
    this.tabs.toArray().forEach(tab => (tab.active = false));
    if (tab) tab.active = true;
    this.tabSelected.emit(index);
  }
}

import { Component, inject } from '@angular/core';
import { LandingCardComponent } from '../../../../../common/components/landing-card/landing-card.component';
import { WhyJoinContraaAnimationComponent } from './why-join-contraa-animation/why-join-contraa-animation.component';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'mars-why-join',
  standalone: true,
  imports: [
    LandingCardComponent,
    WhyJoinContraaAnimationComponent,
    TranslocoModule,
  ],
  templateUrl: './why-join.component.html',
  styleUrl: './why-join.component.scss',
})
export class WhyJoinComponent {
  translocoService = inject(TranslocoService);

  reasons = [
    {
      title: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_1_title'
      ),
      description: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_1_description'
      ),
      svg: `<svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.4"
              d="M40 18V23.84L38.44 22.94C37.16 22.2 35.56 22.42 34.52 23.42C34.3 23.62 34.12 23.86 33.96 24.12L33.84 24.34C33.62 24.72 33.44 24.82 33.42 24.84C33.38 24.82 33.18 24.72 32.96 24.32L32.84 24.12C32.4 23.36 31.7 22.84 30.86 22.62C30 22.38 29.12 22.5 28.38 22.94L26.56 24C25.74 24.46 25.16 25.22 24.92 26.12C24.68 27.04 24.8 27.98 25.26 28.8C25.48 29.16 25.48 29.38 25.48 29.42C25.46 29.44 25.26 29.56 24.82 29.56C22.88 29.56 21.3 31.14 21.3 33.06V34.94C21.3 36.86 22.88 38.44 24.82 38.44C25.26 38.44 25.46 38.56 25.46 38.58C25.48 38.6 25.48 38.84 25.26 39.2C25.12 39.46 25 39.72 24.94 40H18C12.48 40 8 35.52 8 30V18C8 12.48 12.48 8 18 8H30C35.52 8 40 12.48 40 18Z"
              fill="#4720CD" />
            <path
              d="M34.5205 19.4805V23.4205C34.3005 23.6205 34.1205 23.8605 33.9605 24.1205L33.8405 24.3405C33.6205 24.7205 33.4405 24.8205 33.4205 24.8405C33.3805 24.8205 33.1805 24.7205 32.9605 24.3205L32.8405 24.1205C32.4005 23.3605 31.7005 22.8405 30.8605 22.6205C30.0005 22.3805 29.1205 22.5005 28.3805 22.9405L26.5605 24.0005C25.7405 24.4605 25.1605 25.2205 24.9205 26.1205C24.6805 27.0405 24.8005 27.9805 25.2605 28.8005C25.4805 29.1605 25.4805 29.3805 25.4805 29.4205C25.4605 29.4405 25.2605 29.5605 24.8205 29.5605C22.8805 29.5605 21.3005 31.1405 21.3005 33.0605V34.5205H19.4805C16.1805 34.5205 13.4805 31.8205 13.4805 28.5205V19.4805C13.4805 16.1805 16.1805 13.4805 19.4805 13.4805H28.5205C31.8205 13.4805 34.5205 16.1805 34.5205 19.4805Z"
              fill="#4720CD" />
            <path
              d="M18.1201 5.5V8H18.0001C17.0001 8 16.0401 8.14 15.1201 8.42V5.5C15.1201 4.68 15.7801 4 16.6201 4C17.4401 4 18.1201 4.68 18.1201 5.5Z"
              fill="#4720CD" />
            <path
              d="M25.5 5.5V8H22.5V5.5C22.5 4.68 23.18 4 24 4C24.82 4 25.5 4.68 25.5 5.5Z"
              fill="#4720CD" />
            <path
              d="M32.9004 5.5V8.42C31.9804 8.14 31.0004 8 30.0004 8H29.9004V5.5C29.9004 4.68 30.5804 4 31.4004 4C32.2204 4 32.9004 4.68 32.9004 5.5Z"
              fill="#4720CD" />
            <path
              d="M44.0001 16.5996C44.0001 17.4396 43.3401 18.0996 42.5001 18.0996H40.0001V17.9996C40.0001 16.9996 39.8601 16.0196 39.5801 15.0996H42.5001C43.3401 15.0996 44.0001 15.7796 44.0001 16.5996Z"
              fill="#4720CD" />
            <path
              d="M18.1201 40.0001V42.5001C18.1201 43.3201 17.4401 44.0001 16.6201 44.0001C15.7801 44.0001 15.1201 43.3201 15.1201 42.5001V39.5801C16.0401 39.8601 17.0001 40.0001 18.0001 40.0001H18.1201Z"
              fill="#4720CD" />
            <path
              d="M8.42 15.0996C8.14 16.0196 8 16.9996 8 17.9996V18.0996H5.5C4.68 18.0996 4 17.4396 4 16.5996C4 15.7796 4.68 15.0996 5.5 15.0996H8.42Z"
              fill="#4720CD" />
            <path
              d="M8 22.5V25.5H5.5C4.68 25.5 4 24.82 4 24C4 23.16 4.68 22.5 5.5 22.5H8Z"
              fill="#4720CD" />
            <path
              d="M8.42 32.9004H5.5C4.68 32.9004 4 32.2204 4 31.4004C4 30.5604 4.68 29.9004 5.5 29.9004H8V30.0004C8 31.0004 8.14 31.9804 8.42 32.9004Z"
              fill="#4720CD" />
            <path
              opacity="0.4"
              d="M41.9798 31.0601C40.6198 31.0601 39.8398 30.3801 39.8398 29.3801C39.8398 28.9801 39.9598 28.5201 40.2398 28.0401C40.7998 27.0801 40.4598 25.8401 39.4998 25.3001L37.6798 24.2401C36.8398 23.7401 35.7598 24.0401 35.2598 24.8801L35.1398 25.0801C34.1798 26.7401 32.6198 26.7401 31.6598 25.0801L31.5398 24.8801C31.0598 24.0401 29.9598 23.7401 29.1398 24.2401L27.2998 25.3001C26.6598 25.6601 26.2798 26.3401 26.2798 27.0401C26.2798 27.3801 26.3798 27.7201 26.5598 28.0401C26.8398 28.5201 26.9798 28.9801 26.9798 29.3801C26.9798 30.3801 26.1798 31.0601 24.8198 31.0601C23.7198 31.0601 22.7998 31.9601 22.7998 33.0601V34.9401C22.7998 36.0401 23.7198 36.9401 24.8198 36.9401C26.1798 36.9401 26.9798 37.6201 26.9798 38.6201C26.9798 39.0201 26.8398 39.4801 26.5598 39.9601C25.9998 40.9201 26.3398 42.1601 27.2998 42.7001L29.1398 43.7601C29.9598 44.2601 31.0598 43.9601 31.5398 43.1201L31.6598 42.9201C32.6198 41.2601 34.1798 41.2601 35.1398 42.9201L35.2598 43.1201C35.7598 43.9601 36.8398 44.2601 37.6798 43.7601L39.4998 42.7001C40.1398 42.3401 40.5198 41.6601 40.5198 40.9601C40.5198 40.6201 40.4198 40.2801 40.2398 39.9601C39.9598 39.4801 39.8398 39.0201 39.8398 38.6201C39.8398 37.6201 40.6198 36.9401 41.9798 36.9401C43.0998 36.9401 43.9998 36.0401 43.9998 34.9401V33.0601C43.9998 31.9601 43.0998 31.0601 41.9798 31.0601ZM33.3998 37.4401C31.4998 37.4401 29.9598 35.9001 29.9598 34.0001C29.9598 32.1001 31.4998 30.5601 33.3998 30.5601C35.2998 30.5601 36.8398 32.1001 36.8398 34.0001C36.8398 35.9001 35.2998 37.4401 33.3998 37.4401Z"
              fill="#4720CD" />
            <path
              d="M36.84 33.9996C36.84 35.8996 35.3 37.4396 33.4 37.4396C31.5 37.4396 29.96 35.8996 29.96 33.9996C29.96 32.0996 31.5 30.5596 33.4 30.5596C35.3 30.5596 36.84 32.0996 36.84 33.9996Z"
              fill="#4720CD" />
          </svg>`,
    },

    {
      title: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_2_title'
      ),
      description: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_2_description'
      ),
      svg: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.0796 38.0786C40.7796 38.3786 40.3996 38.5186 40.0196 38.5186C39.6396 38.5186 39.2596 38.3786 38.9596 38.0786L29.0596 28.1786L30.1196 27.1186L31.1796 26.0586L41.0796 35.9586C41.6596 36.5386 41.6596 37.4986 41.0796 38.0786Z" fill="#4720CD"/>
<path opacity="0.4" d="M12.9397 18.5602L24.5397 30.1602C25.3197 30.9402 25.3197 32.2002 24.5397 32.9802L22.7397 34.8002C21.1197 36.4002 18.5597 36.4002 16.9597 34.8002L8.27973 26.1202C6.69973 24.5402 6.69973 21.9602 8.27973 20.3602L10.0997 18.5402C10.8797 17.7802 12.1597 17.7802 12.9397 18.5602Z" fill="#4720CD"/>
<path d="M37.18 20.3789L29.56 27.9789C28.76 28.7789 27.48 28.7789 26.68 27.9789L15.14 16.4389C14.34 15.6389 14.34 14.3589 15.14 13.5589L22.76 5.93891C24.34 4.35891 26.92 4.35891 28.52 5.93891L37.2 14.6189C38.76 16.1989 38.76 18.7589 37.18 20.3789Z" fill="#4720CD"/>
<path d="M16 43.5H4C3.18 43.5 2.5 42.82 2.5 42C2.5 41.18 3.18 40.5 4 40.5H16C16.82 40.5 17.5 41.18 17.5 42C17.5 42.82 16.82 43.5 16 43.5Z" fill="#4720CD"/>
</svg>
`,
    },

    {
      title: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_3_title'
      ),
      description: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_3_description'
      ),
      svg: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 10.5C17 11.7856 16.6188 13.0423 15.9046 14.1112C15.1903 15.1801 14.1752 16.0132 12.9874 16.5052C11.7997 16.9972 10.4928 17.1259 9.23192 16.8751C7.97104 16.6243 6.81285 16.0052 5.90381 15.0962C4.99477 14.1872 4.3757 13.029 4.1249 11.7681C3.87409 10.5072 4.00282 9.20028 4.49479 8.01256C4.98676 6.82484 5.81988 5.80968 6.8888 5.09545C7.95772 4.38122 9.21443 4 10.5 4C12.2239 4 13.8772 4.68482 15.0962 5.90381C16.3152 7.12279 17 8.77609 17 10.5Z" fill="#4720CD"/>
<path d="M16 38C16 39.1867 15.6481 40.3467 14.9888 41.3334C14.3295 42.3201 13.3925 43.0891 12.2961 43.5433C11.1997 43.9974 9.99335 44.1162 8.82946 43.8847C7.66558 43.6532 6.59648 43.0818 5.75736 42.2426C4.91825 41.4035 4.3468 40.3344 4.11529 39.1705C3.88378 38.0067 4.0026 36.8003 4.45673 35.7039C4.91085 34.6075 5.67989 33.6705 6.66658 33.0112C7.65328 32.3519 8.81332 32 10 32C11.5913 32 13.1174 32.6321 14.2426 33.7574C15.3679 34.8826 16 36.4087 16 38Z" fill="#4720CD"/>
<path d="M44 38C44 39.1867 43.6481 40.3467 42.9888 41.3334C42.3295 42.3201 41.3925 43.0891 40.2961 43.5433C39.1997 43.9974 37.9933 44.1162 36.8295 43.8847C35.6656 43.6532 34.5965 43.0818 33.7574 42.2426C32.9182 41.4035 32.3468 40.3344 32.1153 39.1705C31.8838 38.0067 32.0026 36.8003 32.4567 35.7039C32.9109 34.6075 33.6799 33.6705 34.6666 33.0112C35.6533 32.3519 36.8133 32 38 32C39.5913 32 41.1174 32.6321 42.2426 33.7574C43.3679 34.8826 44 36.4087 44 38Z" fill="#4720CD"/>
<path opacity="0.4" d="M38.3401 31.9606C37.3942 29.2449 35.627 26.8907 33.2834 25.2244C30.9397 23.558 28.1357 22.662 25.2601 22.6606H25.2001L18.1401 22.6806C16.6483 22.6897 15.195 22.2068 14.0055 21.3065C12.8159 20.4062 11.9564 19.1388 11.5601 17.7006V14.0006C11.5595 13.7994 11.5192 13.6003 11.4414 13.4149C11.3636 13.2294 11.2498 13.0611 11.1066 12.9198C10.9635 12.7785 10.7938 12.667 10.6073 12.5916C10.4208 12.5162 10.2212 12.4784 10.0201 12.4806C9.81994 12.4787 9.62144 12.5167 9.43618 12.5924C9.25092 12.6682 9.08262 12.7801 8.9411 12.9216C8.79958 13.0631 8.68769 13.2314 8.61196 13.4167C8.53624 13.6019 8.4982 13.8004 8.50007 14.0006V36.4405C8.50007 36.8437 8.66021 37.2303 8.94526 37.5154C9.23032 37.8004 9.61694 37.9606 10.0201 37.9606C10.4254 37.9616 10.8147 37.8025 11.1031 37.5177C11.3916 37.233 11.5558 36.8458 11.5601 36.4405V23.2006C13.3641 24.8159 15.6985 25.7127 18.1201 25.7206H18.1601L25.2201 25.7006H25.2601C27.4989 25.6998 29.6823 26.3967 31.5067 27.6942C33.3312 28.9918 34.706 30.8255 35.4401 32.9405C35.5439 33.2394 35.7384 33.4984 35.9966 33.6813C36.2548 33.8642 36.5637 33.9618 36.8801 33.9606C37.0501 33.9633 37.2194 33.9363 37.3801 33.8806C37.5725 33.8225 37.751 33.7258 37.9048 33.5963C38.0585 33.4668 38.1841 33.3073 38.274 33.1275C38.3639 32.9478 38.4161 32.7515 38.4275 32.5509C38.4388 32.3502 38.4091 32.1493 38.3401 31.9606Z" fill="#4720CD"/>
</svg>
`,
    },

    {
      title: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_4_title'
      ),
      description: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_4_description'
      ),
      svg: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M39.08 13.0795L32.94 6.93951C31.06 5.05951 28.52 4.01953 25.86 4.01953H16C10 4.01953 6 8.01953 6 14.0195V34.0195C6 40.0195 10 44.0195 16 44.0195H32C38 44.0195 42 40.0195 42 34.0195V20.1595C42 17.4795 40.94 14.9395 39.08 13.0795Z" fill="#4720CD"/>
<path d="M18.9999 37.4989C18.6199 37.4989 18.2399 37.3589 17.9399 37.0589L13.9399 33.0589C13.3599 32.4789 13.3599 31.5189 13.9399 30.9389L17.9399 26.9389C18.5199 26.3589 19.4799 26.3589 20.0599 26.9389C20.6399 27.5189 20.6399 28.4789 20.0599 29.0589L17.1199 31.9989L20.0599 34.9389C20.6399 35.5189 20.6399 36.4789 20.0599 37.0589C19.7599 37.3589 19.3799 37.4989 18.9999 37.4989Z" fill="#4720CD"/>
<path d="M28.9999 37.4989C28.6199 37.4989 28.2399 37.3589 27.9399 37.0589C27.3599 36.4789 27.3599 35.5189 27.9399 34.9389L30.8799 31.9989L27.9399 29.0589C27.3599 28.4789 27.3599 27.5189 27.9399 26.9389C28.5199 26.3589 29.4799 26.3589 30.0599 26.9389L34.0599 30.9389C34.6399 31.5189 34.6399 32.4789 34.0599 33.0589L30.0599 37.0589C29.7599 37.3589 29.3799 37.4989 28.9999 37.4989Z" fill="#4720CD"/>
</svg>
`,
    },

    {
      title: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_5_title'
      ),
      description: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_5_description'
      ),
      svg: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z" fill="#4720CD"/>
<path d="M23.9998 30.2417C27.446 30.2417 30.2398 27.448 30.2398 24.0017C30.2398 20.5555 27.446 17.7617 23.9998 17.7617C20.5535 17.7617 17.7598 20.5555 17.7598 24.0017C17.7598 27.448 20.5535 30.2417 23.9998 30.2417Z" fill="#4720CD"/>
<path d="M25.5 4V8.08H25.48C25 8.02 24.5 8 24 8C23.5 8 23 8.02 22.52 8.08H22.5V4C22.5 3.18 23.18 2.5 24 2.5C24.82 2.5 25.5 3.18 25.5 4Z" fill="#4720CD"/>
<path d="M8 24C8 24.5 8.02 25.02 8.08 25.5H4C3.18 25.5 2.5 24.82 2.5 24C2.5 23.18 3.18 22.5 4 22.5H8.08C8.02 22.98 8 23.5 8 24Z" fill="#4720CD"/>
<path d="M25.5 39.9219V44.0019C25.5 44.8219 24.82 45.5019 24 45.5019C23.18 45.5019 22.5 44.8219 22.5 44.0019V39.9219H22.52C23 39.9819 23.5 40.0019 24 40.0019C24.5 40.0019 25 39.9819 25.48 39.9219H25.5Z" fill="#4720CD"/>
<path d="M45.4999 24C45.4999 24.82 44.8199 25.5 43.9999 25.5H39.9199C39.9799 25.02 39.9999 24.5 39.9999 24C39.9999 23.5 39.9799 22.98 39.9199 22.5H43.9999C44.8199 22.5 45.4999 23.18 45.4999 24Z" fill="#4720CD"/>
</svg>
`,
    },

    {
      title: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_6_title'
      ),
      description: this.translocoService.translate(
        'landing_page_why_join_contraa_section_card_6_description'
      ),
      svg: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M43.3393 13.8988C42.0593 9.55883 38.4393 5.93883 34.0993 4.65883C30.7993 3.69883 28.5193 3.77883 26.9393 4.95883C25.0393 6.37883 24.8193 8.93883 24.8193 10.7588V15.7388C24.8193 20.6588 27.0593 23.1588 31.4593 23.1588H37.1993C38.9993 23.1588 41.5793 22.9388 42.9993 21.0388C44.2193 19.4788 44.3193 17.1988 43.3393 13.8988Z" fill="#4720CD"/>
<path opacity="0.4" d="M37.8198 26.7183C37.2998 26.1183 36.5398 25.7783 35.7598 25.7783H28.5998C25.0798 25.7783 22.2198 22.9183 22.2198 19.3983V12.2383C22.2198 11.4583 21.8798 10.6983 21.2798 10.1783C20.6998 9.65835 19.8998 9.41835 19.1398 9.51835C14.4398 10.1183 10.1198 12.6983 7.2998 16.5783C4.4598 20.4783 3.4198 25.2383 4.3198 29.9983C5.6198 36.8783 11.1198 42.3783 18.0198 43.6783C19.1198 43.8983 20.2198 43.9983 21.3198 43.9983C24.9398 43.9983 28.4398 42.8783 31.4198 40.6983C35.2998 37.8783 37.8798 33.5583 38.4798 28.8583C38.5798 28.0783 38.3398 27.2983 37.8198 26.7183Z" fill="#4720CD"/>
</svg>
`,
    },
  ];
}

import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { inject } from '@angular/core';
import { Path } from '../constants/paths.constant';
import { NavigationPipe } from '../pipes/navigation/navigation.pipe';
import { ToasterService } from '@mars/ui-components';
import { UserState, UserStateTypes } from '../models/user';
import { RouteStrategyFactory } from './user-strategies/route-strategy.factory';

export const userStateGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const toastService = inject(ToasterService);
  const navigation = new NavigationPipe();
  const url = state.url.split('/')[2];

  const userState = defineStateBasedOnUserState(
    authService.userData()?.user?.state
  );

  if (userState) {
    try {
      const strategy = RouteStrategyFactory.getStrategy(userState.user);
      if (strategy.isAllowed(url, userState.state)) {
        return true;
      } else {
        router.navigate([
          '/' + navigation.transform(strategy.getRedirectPath(userState.state)),
        ]);
        return false;
      }
    } catch (error) {
      toastService.add('Invalid user type', '', '', 'error');
      router.navigate(['/' + navigation.transform(Path.HOME)]);
      return false;
    }
  } else {
    toastService.add('Invalid user state', '', '', 'error');
    router.navigate(['/' + navigation.transform(Path.HOME)]);
    return false;
  }
};

export const defineStateBasedOnUserState = (
  userState: UserState | undefined
):
  | {
      user: 'buyer' | 'supplier' | null;
      state: Exclude<UserStateTypes, null> | '';
    }
  | undefined => {
  if (!userState || (!userState?.supplier && !userState?.buyer)) {
    return { user: null, state: '' };
  } else if (userState.supplier && !userState.buyer) {
    return { user: 'supplier', state: userState.supplier };
  } else if (!userState.supplier && userState.buyer) {
    return { user: 'buyer', state: userState.buyer };
  }
  return undefined;
};

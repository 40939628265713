<section class="at-px-lg md:at-px-6xl at-bg-white at-py-4xl">
  <div class="container at-p-4xl at-bg-primary-500 at-radius-xxl">
    <div class="contraa at-flex at-flex-column">
      <div class="at-flex at-flex-column">
        <p
          class="at-px-md at-py-sm at-radius-xxxl at-bg-buyer-500 at-text-sm at-text-neutral-900 at-mb-2xl">
          {{
            'landing_page_join_supplier_network_section_business_growth'
              | transloco
          }}
        </p>

        <div class="logo at-mb-2xl">
          <svg
            width="215"
            height="36"
            viewBox="0 0 215 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8606 30.137C11.7465 29.5549 10.8689 28.6874 10.2305 27.5344C9.59117 26.3815 9.27246 24.9951 9.27246 23.3735V14.3673C9.27246 11.9247 9.95717 10.0353 11.3275 8.70009C12.697 7.36487 14.4967 6.69727 16.7258 6.69727H32.7125V10.2929H17.1438C15.9361 10.2929 14.9204 10.5616 14.0957 11.0972C13.271 11.6337 12.8596 12.6444 12.8596 14.1284V23.5799C12.8596 25.0639 13.272 26.0746 14.0957 26.6102C14.9195 27.1467 15.9361 27.4154 17.1438 27.4154H32.7125V31.011H16.7258C15.2628 31.011 13.9747 30.72 12.8596 30.1379L12.8606 30.137Z"
              fill="#E5E7EB" />
            <path
              d="M38.2514 29.0073C36.881 27.6712 36.1963 25.7827 36.1963 23.3401V14.3683C36.1963 11.9257 36.881 10.0363 38.2514 8.70106C39.6208 7.36585 41.4205 6.69824 43.6496 6.69824H54.3771C55.8402 6.69824 57.134 6.99485 58.2603 7.58808C59.3867 8.18223 60.2681 9.05532 60.9074 10.2074C61.5458 11.3603 61.8655 12.7476 61.8655 14.3683V23.3401C61.8655 25.7827 61.1741 27.6721 59.7934 29.0073C58.4116 30.3425 56.6062 31.0101 54.3771 31.0101H43.6496C41.4205 31.0101 39.6208 30.3425 38.2514 29.0073ZM57.0422 26.6102C57.866 26.0747 58.2783 25.064 58.2783 23.58V14.1284C58.2783 12.6444 57.866 11.6346 57.0422 11.0972C56.2175 10.5616 55.2018 10.2929 53.9951 10.2929H44.0686C42.8609 10.2929 41.8452 10.5616 41.0205 11.0972C40.1958 11.6337 39.7844 12.6444 39.7844 14.1284V23.58C39.7844 25.064 40.1968 26.0747 41.0205 26.6102C41.8442 27.1467 42.8609 27.4155 44.0686 27.4155H53.9951C55.2018 27.4155 56.2185 27.1477 57.0422 26.6102Z"
              fill="#E5E7EB" />
            <path
              d="M84.6795 30.428C83.7508 29.9482 82.9668 29.2071 82.3284 28.202C81.6891 27.1978 81.0574 25.8161 80.4303 24.0587L77.0522 14.4026C76.5869 13.0107 76.0478 11.9656 75.4321 11.2691C74.8164 10.5727 73.9984 10.2249 72.977 10.2249C71.7929 10.2249 70.9105 10.4816 70.3299 10.9948C69.7492 11.5081 69.3718 12.1367 69.1978 12.8786C69.0238 13.6206 68.9368 14.5625 68.9368 15.7034V31.01H65.3496V14.0939C65.3496 11.7657 65.9823 9.92834 67.2477 8.58104C68.5131 7.23466 70.3998 6.56055 72.9079 6.56055C74.4173 6.56055 75.6648 6.87482 76.6521 7.50245C77.6385 8.13101 78.4282 8.9697 79.0202 10.0195C79.6123 11.0702 80.1873 12.4054 80.7443 14.026L83.9835 23.4088C84.3542 24.4595 84.7259 25.2749 85.0975 25.857C85.4692 26.4391 85.9099 26.8556 86.4216 27.1067C86.9323 27.3587 87.582 27.4842 88.3717 27.4842C89.7411 27.4842 90.6821 27.0555 91.1928 26.1992C91.7035 25.3437 91.9589 24.0253 91.9589 22.2437V6.69723H95.546V22.5868C95.546 25.5771 94.9417 27.752 93.735 29.1104C92.5272 30.4689 90.6925 31.1486 88.2317 31.1486C86.7914 31.1486 85.6073 30.9087 84.6786 30.4289L84.6795 30.428Z"
              fill="#E5E7EB" />
            <path
              d="M107.458 10.2929H97.6357V6.69727H120.868V10.2929H111.08V31.0101H107.458V10.2929Z"
              fill="#E5E7EB" />
            <path
              d="M125.013 8.70107C126.382 7.36585 128.182 6.69824 130.411 6.69824H141.174C143.403 6.69824 145.208 7.30355 146.59 8.51324C147.971 9.72386 148.662 11.4812 148.662 13.7872C148.662 16.0931 147.994 17.9537 146.659 19.0945C145.324 20.2364 143.588 20.8063 141.452 20.8063H139.571C138.363 20.8063 137.416 21.063 136.733 21.5771C136.048 22.0913 135.705 22.93 135.705 24.0942C135.705 25.3494 136.117 26.2179 136.942 26.6967C137.765 27.1765 138.84 27.4164 140.163 27.4164H148.348V31.012H139.501C137.342 31.012 135.565 30.4355 134.172 29.2825C132.778 28.1296 132.081 26.401 132.081 24.0951C132.081 21.9268 132.703 20.2317 133.945 19.0099C135.186 17.7891 136.958 17.1782 139.256 17.1782H141.381C142.356 17.1782 143.203 16.9383 143.923 16.4594C144.643 15.9797 145.003 15.0787 145.003 13.7537C145.003 12.4287 144.596 11.5221 143.784 11.0312C142.971 10.5402 141.996 10.2948 140.858 10.2948H130.826C129.619 10.2948 128.603 10.5635 127.778 11.0991C126.954 11.6356 126.542 12.6574 126.542 14.1637V31.012H122.955V14.3692C122.955 11.9266 123.64 10.0372 125.01 8.70199L125.013 8.70107Z"
              fill="#E5E7EB" />
            <path
              d="M183.457 8.70107C184.827 7.36585 186.627 6.69824 188.856 6.69824H199.688C201.151 6.69824 202.445 6.99485 203.571 7.58808C204.698 8.18223 205.579 9.05532 206.219 10.2074C206.857 11.3603 207.177 12.7476 207.177 14.3683V31.011H203.589V14.1293C203.589 12.6453 203.177 11.6356 202.353 11.0981C201.529 10.5626 200.513 10.2938 199.306 10.2938H189.275C188.067 10.2938 187.051 10.5626 186.227 11.0981C185.402 11.6346 184.991 12.6453 184.991 14.1293V31.011H181.403V14.3683C181.403 11.9257 182.088 10.0363 183.458 8.70107H183.457Z"
              fill="#E5E7EB" />
            <path
              d="M200.002 20.3271H188.612V23.8539H200.002V20.3271Z"
              fill="#E5E7EB" />
            <path
              d="M154.217 8.70107C155.587 7.36585 157.386 6.69824 159.615 6.69824H170.448C171.911 6.69824 173.205 6.99485 174.331 7.58808C175.458 8.18223 176.339 9.05532 176.978 10.2074C177.617 11.3603 177.936 12.7476 177.936 14.3683V31.011H174.349V14.1293C174.349 12.6453 173.937 11.6356 173.113 11.0981C172.288 10.5626 171.273 10.2938 170.066 10.2938H160.034C158.827 10.2938 157.811 10.5626 156.986 11.0981C156.162 11.6346 155.75 12.6453 155.75 14.1293V31.011H152.163V14.3683C152.163 11.9257 152.848 10.0363 154.218 8.70107H154.217Z"
              fill="#E5E7EB" />
            <path
              d="M170.762 20.3271H159.372V23.8539H170.762V20.3271Z"
              fill="#E5E7EB" />
          </svg>
        </div>

        <p
          class="financial-success at-px-md at-py-sm at-radius-xxxl at-bg-supplier-500 at-text-sm at-text-white at-mb-6xl">
          {{
            'landing_page_join_supplier_network_section_financial_success'
              | transloco
          }}
        </p>
      </div>
    </div>

    <div class="content at-text-white at-text-center">
      <p class="title at-text-lg at-h5 at-fw-semibold at-mb-sm">
        {{ 'landing_page_join_supplier_network_section_title' | transloco }}
      </p>
      <p class="description at-text-sm at-mb-2xl">
        {{
          'landing_page_join_supplier_network_section_description' | transloco
        }}
      </p>

      <button
        type="button"
        class="at-text-primary-500 at-fw-medium at-text-md at-bg-primary-50 at-px-xl at-py-sm at-radius-sm">
        {{
          'landing_page_join_supplier_network_section_button_label' | transloco
        }}
      </button>
    </div>
  </div>
</section>

import { Component, ViewEncapsulation } from '@angular/core';
import { FooterComponent } from '../../common/components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { TopNavbarComponent } from '../../common/components/top-navbar/top-navbar.component';

@Component({
  selector: 'mars-landing',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, TopNavbarComponent],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LandingComponent {}

<nav
  aria-label="navigation"
  class="at-py-xl at-bg-white"
  [style.direction]="!mainService.showNavbarLinks() ? 'ltr' : ''">
  <div
    class="nav-content-container at-flex at-justify-between at-align-center at-px-lg md:at-px-6xl">
    <!-- Logo -->
    <a [routerLink]="'/' + (Path.HOME | navigation)">
      <mars-logo />
    </a>

    <!-- links -->
    @if (mainService.showNavbarLinks()) {
      <ul class="at-none xl:at-flex at-gap-4xl at-text-sm at-fw-medium">
        @for (link of navbarLinks; track link.label) {
          <li>
            <a
              class="at-text-primary-500"
              [routerLink]="'/' + (link.path | navigation)"
              routerLinkActive="active-route">
              {{ link.label }}
            </a>
          </li>
        }
      </ul>
    }

    <!-- lang -->
    <div class="at-flex at-align-center at-gap-lg">
      @if (mainService.showNavbarLinks()) {
        <button
          type="button"
          (click)="switchLanguage()"
          class="at-flex at-gap-sm at-align-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#0A0B09"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961"
              stroke="#0A0B09"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M15 3C16.95 8.84 16.95 15.16 15 21"
              stroke="#0A0B09"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
              stroke="#0A0B09"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M3 8.99961C8.84 7.04961 15.16 7.04961 21 8.99961"
              stroke="#0A0B09"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="at-ff-main-ar">{{
            lang() === 'en' ? 'عربي' : 'EN'
          }}</span>
        </button>

        <!-- Action Buttons -->
        @if (!authService.isLoggedIn()) {
          <div class="at-none md:at-flex at-gap-md">
            <a
              [routerLink]="'/' + (Path.LOGIN | navigation)"
              class="at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-border at-border-neutral-300 at-radius-sm at-text-neutral-700 at-fw-medium">
              {{ 'top_navbar_login_button_label' | transloco }}
            </a>
            <a
              [routerLink]="'/' + (Path.SIGN_UP | navigation)"
              class="at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-bg-primary-500 at-radius-sm at-text-white at-fw-medium">
              {{ 'top_navbar_sign_up_button_label' | transloco }}
            </a>
          </div>
        }
      }
      @if (authService.isLoggedIn()) {
        <mars-user-info (logout)="logout()" [isLoggingOut]="isLoggingOut()" />
      }

      <!-- menu side mobile nav -->
      <button
        (click)="toggleMobileNavbar()"
        type="button"
        class="at-inline-block xl:at-none">
        @if (!isMobileNavbarOpened) {
          <svg
            class="at-inline-block"
            width="44"
            height="40"
            viewBox="0 0 44 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M29.5 16.458H14.5C14.1583 16.458 13.875 16.1747 13.875 15.833C13.875 15.4913 14.1583 15.208 14.5 15.208H29.5C29.8417 15.208 30.125 15.4913 30.125 15.833C30.125 16.1747 29.8417 16.458 29.5 16.458Z"
              fill="#06080D" />
            <path
              d="M29.5 20.625H14.5C14.1583 20.625 13.875 20.3417 13.875 20C13.875 19.6583 14.1583 19.375 14.5 19.375H29.5C29.8417 19.375 30.125 19.6583 30.125 20C30.125 20.3417 29.8417 20.625 29.5 20.625Z"
              fill="#06080D" />
            <path
              d="M29.5 24.792H14.5C14.1583 24.792 13.875 24.5087 13.875 24.167C13.875 23.8253 14.1583 23.542 14.5 23.542H29.5C29.8417 23.542 30.125 23.8253 30.125 24.167C30.125 24.5087 29.8417 24.792 29.5 24.792Z"
              fill="#06080D" />
          </svg>
        }

        @if (isMobileNavbarOpened) {
          <svg
            class="at-inline-block"
            width="44"
            height="40"
            viewBox="0 0 44 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.094 23.9779L18.0229 16.9069C17.7813 16.6653 17.7813 16.2646 18.0229 16.023C18.2645 15.7814 18.6652 15.7814 18.9068 16.023L25.9779 23.0941C26.2194 23.3357 26.2194 23.7364 25.9779 23.9779C25.7363 24.2195 25.3356 24.2195 25.094 23.9779Z"
              fill="#06080D" />
            <path
              d="M18.0221 23.9779C17.7806 23.7364 17.7806 23.3357 18.0221 23.0941L25.0932 16.023C25.3348 15.7814 25.7355 15.7814 25.9771 16.023C26.2187 16.2646 26.2187 16.6653 25.9771 16.9069L18.906 23.9779C18.6644 24.2195 18.2637 24.2195 18.0221 23.9779Z"
              fill="#06080D" />
          </svg>
        }
      </button>
    </div>
  </div>
</nav>

@if (isMobileNavbarOpened) {
  <nav class="xl:at-none at-block mobile-navbar" aria-label="Site mobile menu">
    <div class="at-mb-xl">
      <!-- links -->
      <ul
        class="at-flex at-flex-column at-text-center at-gap-4xl at-text-md at-fw-medium at-px-2xl">
        @for (link of navbarLinks; track link.label) {
          <li>
            <a
              class="at-text-primary-500"
              (click)="isMobileNavbarOpened = false"
              [routerLink]="'/' + (link.path | navigation)"
              routerLinkActive="active-route">
              {{ link.label }}
            </a>
          </li>
        }

        <!-- Action Buttons -->
        <li class="at-block md:at-none">
          <div class="at-flex at-justify-between at-gap-md">
            @if (!authService.isLoggedIn()) {
              <a
                [routerLink]="'/' + (Path.LOGIN | navigation)"
                class="at-w-100 at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-border at-border-neutral-300 at-radius-sm at-text-neutral-700 at-fw-medium">
                {{ 'top_navbar_login_button_label' | transloco }}
              </a>
              <a
                [routerLink]="'/' + (Path.SIGN_UP | navigation)"
                class="at-w-100 at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-bg-primary-500 at-radius-sm at-text-white at-fw-medium">
                {{ 'top_navbar_sign_up_button_label' | transloco }}
              </a>
            } @else {
              <div class="at-w-100 at-flex at-flex-column at-gap-md">
                <a
                  (click)="authService.logOut()"
                  class="at-w-100 at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-bg-primary-500 at-radius-sm at-text-white at-fw-medium">
                  Logout
                </a>
                @if (!authService.userData()?.user?.is_active) {
                  <a
                    [routerLink]="'/' + (Path.WELCOME_TO_CONTRAA | navigation)"
                    class="at-text-md md:at-text-sm at-cursor-pointer at-px-xl at-py-sm at-bg-supplier-500 at-radius-sm at-text-white at-fw-medium">
                    Complete your profile
                  </a>
                }
              </div>
            }
          </div>
        </li>
      </ul>
    </div>
  </nav>
}
